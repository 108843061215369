<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                href="#"
                class="aluno_font_color"                
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li> 
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Cursos</a>
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Cursos <span>cadastrados</span>
                </h1>

                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div class="col-12 mt-2">
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <h6 class="m-0 font-weight-bold text-primary">
                            Lista de cursos
                          </h6>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                          <button
                            class="btn btn-sm btn-primary"
                            @click.prevent="showModal('modalCriarSecretariaCurso')"
                          >
                            <small>+ Criar curso</small>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-3 mb-1">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="Filtro por nome do curso"
                            @keyup="filtroNomeCurso"
                          >
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-2 mb-1">
                          <select
                            v-model="filtroNivelEscolhido"
                            class="form-control form-control-sm"
                            @change="aplicaFiltroCursos()"
                          >
                            <option value="">
                              -- Filtrar por nível --
                            </option>
                            <option
                              v-for="(nivel, index) in filtroNiveis"
                              :key="index"
                              :value="nivel"
                            >
                              <span>{{ retornaCursoNivel(nivel) }}</span>
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 table-responsive mt-2">
                          <table class="table table-sm">
                            <thead class="thead-dark">
                              <tr>
                                <th>
                                  <small
                                    class="font-weight-bold"
                                  >Nome do curso</small>
                                </th>
                                <th>
                                  <small class="font-weight-bold">Nível</small>                                  
                                </th>
                                <th class="text-center">
                                  <small
                                    class="font-weight-bold"
                                  >Carga horária</small>
                                </th>
                                <th class="text-center">
                                  <small
                                    class="font-weight-bold"
                                  >Preço</small>
                                </th>
                                <th class="text-center">
                                  <small
                                    class="font-weight-bold"
                                  >Publicado</small>
                                </th>
                                <th class="text-center">
                                  <small class="font-weight-bold">Ações</small>
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="fastSecretariaCursosLoading">
                              <tr>
                                <td
                                  colspan="6"
                                  class="text-center"
                                  v-html="fastLoadingDados()"
                                />
                              </tr>
                            </tbody>
                            <tbody v-else-if="fastSecretariaCursosFiltro.length">
                              <tr
                                v-for="curso in pageOfCursos"
                                :key="curso.id_secretaria_curso"
                              >
                                <td class="align-middle">
                                  <small>{{ curso.nome_curso }}</small>  
                                </td>  
                                <td class="align-middle">
                                  <small>{{ retornaCursoNivel(curso.nivel) }}</small> 
                                </td>  
                                <td class="align-middle text-center">
                                  <span v-if="curso.carga_horaria">
                                    <small>{{ curso.carga_horaria }}h</small>                                    
                                  </span>                                  
                                </td> 
                                <td class="align-middle text-center">
                                  <small v-if="curso.preco">R$ {{ formataPreco(curso.preco) }}</small>
                                  <small
                                    v-else
                                    class="text-danger"
                                  >Indefinido</small>                           
                                </td> 
                                <td class="align-middle text-center">
                                  <span
                                    v-if="curso.publicado"
                                    class="btn btn-sm btn-success pt-0 pb-0"
                                  >
                                    <small>Sim</small>
                                  </span>
                                  <span
                                    v-else
                                    class="btn btn-sm btn-danger pt-0 pb-0"
                                  >
                                    <small>Não</small>
                                  </span>
                                </td>                          
                                <td class="text-center align-middle">  
                                  <a
                                    :href="
                                      '/plataforma/' +
                                        $route.params.id_plataforma +
                                        '/secretaria/curso/' +
                                        curso.id_secretaria_curso
                                    "
                                    class="btn btn-sm btn-success pt-0 pb-0 mr-2"
                                    @click.prevent="$router.push('/plataforma/' +
                                      $route.params.id_plataforma +
                                      '/secretaria/curso/' +
                                      curso.id_secretaria_curso)"
                                  >
                                    <small>Módulos
                                      <span class="badge badge-light">{{ curso.total_programas }}</span>
                                    </small>
                                  </a>   
                                                           
                                  <button
                                    class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                    @click="exibeModalEditarSecretariaCurso(curso)"
                                  >
                                    <small>Editar</small>
                                  </button>
                                  <button
                                    class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                                    @click="exibeModalExcluirSecretariaCurso(curso)"
                                  >
                                    <small>Excluir</small>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td
                                  colspan="6"
                                  class="text-center"
                                >
                                  Nenhum curso cadastrado
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-12 text-center mt-4">
                          <Pagination
                            :page-size="10"
                            :items="fastSecretariaCursosFiltro"
                            @changePage="pageOfCursos = $event"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' +
                      $route.params.id_plataforma +
                      '/secretaria'
                  "
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalCriarSecretariaCurso"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Novo curso</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarSecretariaCurso')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-8 mb-4">
              <label>Nome do curso</label>
              <input
                v-model="fastSecretariaCursoNovo.nome_curso"
                type="text"
                class="form-control"
                maxlength="100"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
              <label>Nível</label>
              <select
                v-model="fastSecretariaCursoNovo.nivel"
                class="form-control"
              >                
                <option value="F">
                  Ensino fundamental
                </option>
                <option value="E">
                  Ensino médio
                </option>
                <option value="J">
                  EJA
                </option>
                <option value="S">
                  Ensino superior
                </option>                
                <option value="T">
                  Técnico
                </option>
                <option value="P">
                  Pós-graduação
                </option>
                <option value="M">
                  Mestrado
                </option>
                <option value="D">
                  Doutorado
                </option>
              </select>              
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
              <label>Carga horária</label>
              <input
                v-model="fastSecretariaCursoNovo.carga_horaria"
                type="number"
                class="form-control"                
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
              <label>Créditos totais</label>
              <input
                v-model="fastSecretariaCursoNovo.creditos_total"
                type="number"
                class="form-control"                
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
              <label>Preço do curso</label>
              <money
                v-model="fastSecretariaCursoNovo.preco"
                class="form-control"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
              <label>Descrição legal</label>
              <textarea
                v-model="fastSecretariaCursoNovo.descricao_legal"
                class="form-control"
                maxlength="255"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
              <label>Detalhes do curso</label>
              <textarea
                v-model="fastSecretariaCursoNovo.detalhe_curso"
                class="form-control"
                maxlength="200"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-8">
                  <label>Base curricular</label>
                  <div v-if="fastBaseCurricularLoading">
                    <b-icon
                      icon="gear-fill"
                      animation="spin"
                    /> Carregando
                  </div>
                  <select
                    v-else
                    v-model="fastSecretariaCursoNovo.id_base_curricular"
                    class="form-control"
                  >  
                    <option value="0">
                      ---------------------- Nenhuma ----------------------
                    </option>
                    <option
                      v-for="(e, index) in fastBaseCurricular"
                      :key="index"
                      :value="e.id_base_curricular"
                    >
                      {{ e.nome_base_curricular }} - {{e.ano_faixa}}º ano
                    </option>
                  </select>
                </div>
              </div>              
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
              <label>Publicado</label>
              <div class="fd-app-curso-aulas mt-0">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="col-12 text-center">
                        <label class="switch">
                          <input
                            v-model="fastSecretariaCursoNovo.publicado"
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>              
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
              <label>Venda por módulo</label>
              <div class="fd-app-curso-aulas mt-0">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="col-12 text-center">
                        <label class="switch">
                          <input
                            v-model="fastSecretariaCursoNovo.venda_modulo"
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>              
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
              <label>Venda por unidade</label>
              <div class="fd-app-curso-aulas mt-0">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="col-12 text-center">
                        <label class="switch">
                          <input
                            v-model="fastSecretariaCursoNovo.venda_unidade_curricular"
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>              
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
              <label>Possui aproveitamento</label>
              <div class="fd-app-curso-aulas mt-0">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="col-12 text-center">
                        <label class="switch">
                          <input
                            v-model="fastSecretariaCursoNovo.possui_aproveitamento"
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>              
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
              <label>Gera certificado</label>
              <div class="fd-app-curso-aulas mt-0">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="col-12 text-center">
                        <label class="switch">
                          <input
                            v-model="fastSecretariaCursoNovo.gera_certificado"
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>              
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
              <label>Disponível na loja virtual</label>
              <div class="fd-app-curso-aulas mt-0">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="col-12 text-center">
                        <label class="switch">
                          <input
                            v-model="fastSecretariaCursoNovo.loja_virtual"
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>              
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
              <label>Logo do curso</label>              
              <div
                v-if="fastSecretariaCursoNovo.logo_curso"
                class="mb-2"
              >
                <img
                  :src="fastSecretariaCursoNovo.logo_curso"
                  style="max-width: 300px"
                >
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12 mt-4 p-0">
                <!-- upload aqui -->
                <input
                  id="logo_curso_novo"
                  type="file"
                  class="custom-file-input"
                  accept="image/*"
                  @change="setImageLogoCursoNovo"
                >
                <label
                  class="custom-file-label"
                  for="logo_curso_novo"
                >Selecione uma imagem...</label>
                <label class="message-field-label mt-2">Dimensões sugeridas: 952 x 519 pixels</label>             
                <div class="invalid-feedback">
                  Arquivo inválido
                </div>
                <modal
                  name="modalImageCropLogoCursoNovo"
                  height="auto"
                  :shift-y="0.1"
                  :focus-trap="true"
                  :adaptive="true"
                >
                  <div class="row">
                    <div class="col-12 pt-4 text-center">
                      <h4>Recorte a imagem</h4>
                    </div>
                    <div
                      class="col-sm-12 col-md-12 col-lg-12 p-4 text-center"
                    >
                      <vue-cropper
                        ref="cropper"
                        :src="fastSecretariaCursoNovo.logo_curso"
                        :auto-crop-area="1"
                        :aspect-ratio="159 / 85"
                      />
                      <div class="text-center mt-2">
                        <a
                          class="btn btn-primary"
                          href="#"
                          role="button"
                          @click.prevent="
                            cropImageNovo()
                          "
                        >Recortar</a>
                      </div>
                    </div>
                  </div>
                </modal>
              </div>
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="criarSecretariaCurso()"
                >
                  Criar curso
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarSecretariaCurso"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar curso</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarSecretariaCurso')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-8 mb-4">
              <label>Nome do curso</label>
              <input
                v-model="fastSecretariaCursoEditar.nome_curso"
                type="text"
                class="form-control"
                maxlength="100"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
              <label>Nível</label>
              <select
                v-model="fastSecretariaCursoEditar.nivel"
                class="form-control"
              >                
                <option value="F">
                  Ensino fundamental
                </option>
                <option value="E">
                  Ensino médio
                </option>
                <option value="J">
                  EJA
                </option>
                <option value="S">
                  Ensino superior
                </option>                
                <option value="T">
                  Técnico
                </option>
                <option value="P">
                  Pós-graduação
                </option>
                <option value="M">
                  Mestrado
                </option>
                <option value="D">
                  Doutorado
                </option>
              </select>              
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
              <label>Carga horária</label>
              <input
                v-model="fastSecretariaCursoEditar.carga_horaria"
                type="number"
                class="form-control"                
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
              <label>Créditos totais</label>
              <input
                v-model="fastSecretariaCursoEditar.creditos_total"
                type="number"
                class="form-control"                
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
              <label>Preço do curso</label>
              <money
                v-model="fastSecretariaCursoEditar.preco"
                class="form-control"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
              <label v-if="fastSecretariaCursoDocumentos.loading"><b-icon icon="gear-fill" animation="spin" /> Carregando arquivos</label>
              <label v-else>Arquivos do curso ({{fastSecretariaCursoDocumentos.total.length}})</label>
              <div>
                <button class="btn btn-primary" @click="exibeModalDocumentosCurso(fastSecretariaCursoEditar.id_secretaria_curso)">Editar</button>  
              </div>             
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
              <label>Descrição legal</label>
              <textarea
                v-model="fastSecretariaCursoEditar.descricao_legal"
                class="form-control"
                maxlength="255"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
              <label>Detalhes do curso</label>
              <textarea
                v-model="fastSecretariaCursoEditar.detalhe_curso"
                class="form-control"
                maxlength="200"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-6">
                  <label>Base curricular</label>
                  <div v-if="fastBaseCurricularLoading">
                    <b-icon
                      icon="gear-fill"
                      animation="spin"
                    /> Carregando
                  </div>
                  <select
                    v-else
                    v-model="fastSecretariaCursoEditar.id_base_curricular"
                    class="form-control"
                  >  
                    <option value="0">
                      -- Nenhuma --
                    </option>
                    <option
                      v-for="(e, index) in fastBaseCurricular"
                      :key="index"
                      :value="e.id_base_curricular"
                    >
                      {{ e.nome_base_curricular }} - {{e.ano_faixa}} ano
                    </option>
                  </select>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6 text-center">
                  <label>Requisitos de matrícula</label>
                  <div>
                    <button class="btn btn-primary" @click="exibeModalAlteraRequisitosDeMatricula(fastSecretariaCursoEditar)">Editar</button>  
                  </div> 
                </div>
              </div>              
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
              <label>Publicado</label>
              <div class="fd-app-curso-aulas mt-0">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="col-12 text-center">
                        <label class="switch">
                          <input
                            v-model="fastSecretariaCursoEditar.publicado"
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>              
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
              <label>Venda por módulo</label>
              <div class="fd-app-curso-aulas mt-0">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="col-12 text-center">
                        <label class="switch">
                          <input
                            v-model="fastSecretariaCursoEditar.venda_modulo"
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>              
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
              <label>Venda por unidade</label>
              <div class="fd-app-curso-aulas mt-0">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="col-12 text-center">
                        <label class="switch">
                          <input
                            v-model="fastSecretariaCursoEditar.venda_unidade_curricular"
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>              
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
              <label>Possui aproveitamento</label>
              <div class="fd-app-curso-aulas mt-0">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="col-12 text-center">
                        <label class="switch">
                          <input
                            v-model="fastSecretariaCursoEditar.possui_aproveitamento"
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>              
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
              <label>Gera certificado</label>
              <div class="fd-app-curso-aulas mt-0">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="col-12 text-center">
                        <label class="switch">
                          <input
                            v-model="fastSecretariaCursoEditar.gera_certificado"
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>              
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center d-flex flex-column">
              <label>Clonar curso</label>
              <button class="btn btn-primary" @click="exibeModalClonarCurso">
                Clonar
              </button>
            </div>
            
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Logo do curso</label>              
              <div
                v-if="fastSecretariaCursoEditar.logo_curso"
                class="mb-2"
              >
                <img
                  :src="fastSecretariaCursoEditar.logo_curso"
                  style="max-width: 300px"
                >
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12 mt-4 p-0">
                <!-- upload aqui -->
                <input
                  id="logo_curso_editar"
                  type="file"
                  class="custom-file-input"
                  accept="image/*"
                  @change="setImageLogoCursoEditar"
                >
                <label class="message-field-label mt-2">Dimensões sugeridas: 952 x 519 pixels</label>             
                <label
                  class="custom-file-label"
                  for="logo_curso_editar"
                >Selecione uma imagem...</label>
                <div class="invalid-feedback">
                  Arquivo inválido
                </div>
                <modal
                  name="modalImageCropLogoCursoEditar"
                  height="auto"
                  :shift-y="0.1"
                  :focus-trap="true"
                  :adaptive="true"
                >
                  <div class="row">
                    <div class="col-12 pt-4 text-center">
                      <h4>Recorte a imagem</h4>
                    </div>
                    <div
                      class="col-sm-12 col-md-12 col-lg-12 p-4 text-center"
                    >
                      <vue-cropper
                        ref="cropper"
                        :src="fastSecretariaCursoEditar.logo_curso"
                        :auto-crop-area="1"
                        :aspect-ratio="159 / 85"
                      />
                      <div class="text-center mt-2">
                        <a
                          class="btn btn-primary"
                          href="#"
                          role="button"
                          @click.prevent="
                            cropImageEditar()
                          "
                        >Recortar</a>
                      </div>
                    </div>
                  </div>
                </modal>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Disponível na loja virtual</label>
              <div class="fd-app-curso-aulas mt-0">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="col-12 text-center">
                        <label class="switch">
                          <input
                            v-model="fastSecretariaCursoEditar.loja_virtual"
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="editarSecretariaCurso()"
                >
                  Confirmar alterações
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalAlterarRequisitosMatricula"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar Requisitos de matricula</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalAlterarRequisitosMatricula')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div>
          <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
            <label class="col-sm-12 col-md-12 col-lg-3 text-center">Bairro:
            <div class="fd-app-curso-aulas mt-0">
              <div class="fd-app-curso-aulas-list mt-0">
                <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="fastSecretariaCursoRequisitos.bairro"
                          type="checkbox"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </label>
            <label class="col-sm-12 col-md-12 col-lg-3 text-center">CEP:
            <div class="fd-app-curso-aulas mt-0">
              <div class="fd-app-curso-aulas-list mt-0">
                <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="fastSecretariaCursoRequisitos.cep"
                          type="checkbox"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </label>
            <label class="col-sm-12 col-md-12 col-lg-3 text-center">Cidade:
            <div class="fd-app-curso-aulas mt-0">
              <div class="fd-app-curso-aulas-list mt-0">
                <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="fastSecretariaCursoRequisitos.cidade"
                          type="checkbox"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </label>
            <label class="col-sm-12 col-md-12 col-lg-3 text-center">CPF:
            <div class="fd-app-curso-aulas mt-0">
              <div class="fd-app-curso-aulas-list mt-0">
                <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="fastSecretariaCursoRequisitos.cpf_cnpj"
                          type="checkbox"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </label>
            <label class="col-sm-12 col-md-12 col-lg-3 text-center">Complemento:
            <div class="fd-app-curso-aulas mt-0">
              <div class="fd-app-curso-aulas-list mt-0">
                <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="fastSecretariaCursoRequisitos.complemento"
                          type="checkbox"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </label>
            <label class="col-sm-12 col-md-12 col-lg-3 text-center">Data Nascimento:
            <div class="fd-app-curso-aulas mt-0">
              <div class="fd-app-curso-aulas-list mt-0">
                <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="fastSecretariaCursoRequisitos.datanascimento_abertura"
                          type="checkbox"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </label>
            <label class="col-sm-12 col-md-12 col-lg-3 text-center">País do Endereço:
            <div class="fd-app-curso-aulas mt-0">
              <div class="fd-app-curso-aulas-list mt-0">
                <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="fastSecretariaCursoRequisitos.id_pais_endereco"
                          type="checkbox"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </label>
            <label class="col-sm-12 col-md-12 col-lg-3 text-center">Nacionalidade:
            <div class="fd-app-curso-aulas mt-0">
              <div class="fd-app-curso-aulas-list mt-0">
                <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="fastSecretariaCursoRequisitos.id_pais_pessoa"
                          type="checkbox"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </label>
            <label class="col-sm-12 col-md-12 col-lg-3 text-center">DDI:
            <div class="fd-app-curso-aulas mt-0">
              <div class="fd-app-curso-aulas-list mt-0">
                <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="fastSecretariaCursoRequisitos.id_pais_telefone"
                          type="checkbox"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </label>
            <label class="col-sm-12 col-md-12 col-lg-3 text-center">Logradouro:
            <div class="fd-app-curso-aulas mt-0">
              <div class="fd-app-curso-aulas-list mt-0">
                <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="fastSecretariaCursoRequisitos.logradouro"
                          type="checkbox"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </label>
            <label class="col-sm-12 col-md-12 col-lg-3 text-center">Naturalidade:
            <div class="fd-app-curso-aulas mt-0">
              <div class="fd-app-curso-aulas-list mt-0">
                <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="fastSecretariaCursoRequisitos.naturalidade"
                          type="checkbox"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </label>
            <label class="col-sm-12 col-md-12 col-lg-3 text-center">Nome:
            <div class="fd-app-curso-aulas mt-0">
              <div class="fd-app-curso-aulas-list mt-0">
                <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="fastSecretariaCursoRequisitos.nome_razao"
                          type="checkbox"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </label>
            <label class="col-sm-12 col-md-12 col-lg-3 text-center">Número:
            <div class="fd-app-curso-aulas mt-0">
              <div class="fd-app-curso-aulas-list mt-0">
                <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="fastSecretariaCursoRequisitos.numero"
                          type="checkbox"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </label>
            <label class="col-sm-12 col-md-12 col-lg-3 text-center">Órgão Emissor:
            <div class="fd-app-curso-aulas mt-0">
              <div class="fd-app-curso-aulas-list mt-0">
                <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="fastSecretariaCursoRequisitos.orgao_emissor"
                          type="checkbox"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </label>
            <label class="col-sm-12 col-md-12 col-lg-3 text-center">RG:
            <div class="fd-app-curso-aulas mt-0">
              <div class="fd-app-curso-aulas-list mt-0">
                <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="fastSecretariaCursoRequisitos.rg"
                          type="checkbox"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </label>
            <label class="col-sm-12 col-md-12 col-lg-3 text-center">Sexo:
            <div class="fd-app-curso-aulas mt-0">
              <div class="fd-app-curso-aulas-list mt-0">
                <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="fastSecretariaCursoRequisitos.sexo"
                          type="checkbox"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </label>
            <label class="col-sm-12 col-md-12 col-lg-3 text-center">Sobrenome:
            <div class="fd-app-curso-aulas mt-0">
              <div class="fd-app-curso-aulas-list mt-0">
                <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="fastSecretariaCursoRequisitos.sobrenome_fantasia"
                          type="checkbox"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </label>
            <label class="col-sm-12 col-md-12 col-lg-3 text-center">Telefone:
            <div class="fd-app-curso-aulas mt-0">
              <div class="fd-app-curso-aulas-list mt-0">
                <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="fastSecretariaCursoRequisitos.telefone"
                          type="checkbox"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </label>
            <label class="col-sm-12 col-md-12 col-lg-3 text-center">UF:
            <div class="fd-app-curso-aulas mt-0">
              <div class="fd-app-curso-aulas-list mt-0">
                <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="fastSecretariaCursoRequisitos.uf"
                          type="checkbox"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </label>
          </div>
          <div class="text-center">
            <button class="btn btn-primary mb-2" @click="cadastraRequisitosDeFormulario">Salvar alterações de campos do formulário</button>
          </div>
          <div v-if="fastSecretariaCursoRequisitos.id_matricula_requisito === 0">
            <p class="text-danger text-center p-5">O ativação de documentos fica disponível após salvar os requisitos de campos de formulário.</p>
          </div>
          <div v-else>
            <table class="table table-sm">
              <thead class="thead-dark">
                <tr>
                  <th class="text-center">Nome</th>
                  <th class="text-center">Doc Obrigatório?</th>
                  <th class="text-center">Inserir/Remover</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="doc in fastSecretariaTiposDeDocumentoAtivos"
                  :key="doc.tipo_documento"
                >
                  <td class="pt-3 text-center">
                    {{ doc.nome_documento }}
                  </td>
                  <td class="pt-3 text-center" v-if="doc.ativo">
                    <div class="fd-app-curso-aulas mt-0">
                      <div class="fd-app-curso-aulas-list mt-0">
                        <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                          <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                            <div class="col-12 text-center">
                              <label class="switch">
                                <input
                                  type="checkbox"
                                  v-model="doc.obrigatorio"
                                  @change="atualizaDocumentoRequisito(doc)"
                                >
                                <span class="slider round" />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td v-else class="text-center pt-3">
                    -
                  </td>
                  <td class="pt-3 text-center" v-if="!doc.ativo">
                    <button class="btn btn-primary" @click="ativaDocumentoRequisito(doc)">Inserir</button>
                  </td>
                  <td class="pt-3 text-center" v-else>
                    <button class="btn btn-danger" @click="inativaDocumentoRequisito(doc)">Remover</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExcluirSecretariaCurso"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir curso?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirSecretariaCurso')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="excluirSecretariaCurso()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalClonarCurso"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Clonar curso?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalClonarCurso')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 row">
              <div class="col-6 d-flex flex-column">
                <label>
                  Sufixo do clone:
                  <input type="text" class="form-control" v-model="fastCursoSufixoNomeClone">
                </label>
                <small class="text-secondary">Sufixo a ser adicionado ao nome do curso e das unidadades na nova cópia, para que seja possível diferenciar a nova cópia do original</small>
              </div>
              <div class="col-6 form-group mb-0 d-flex justify-content-end align-self-center">
                <div>
                  <button
                    v-if="!fastCursoSufixoNomeClone"
                    href="#"
                    type="button"
                    class="btn btn-primary"
                    @click.prevent="clonarCurso()"
                    disabled
                  >
                    Confirmar
                  </button>
                  <button
                    v-else
                    href="#"
                    type="button"
                    class="btn btn-primary"
                    @click.prevent="clonarCurso()"
                  >
                    Confirmar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <b-modal id="modalExibirDocumentosCurso" size="xl" scrollable title="Arquivos de legalização do curso" >
      <div class="row">
        <div class="col-12 mb-2 text-right">          
          <button class="btn btn-sm btn-primary" @click="showModalBootstrap('modalCadastrarDocumentosCurso', 'modalExibirDocumentosCurso')">Novo documento</button>
        </div>
        <div class="col-12 table-responsive p-4">
            <table class="table table-sm table-striped">
              <thead class="thead-dark">
                <tr>
                  <th>Documento</th>
                  <th>Descrição</th>
                  <th class="text-center">
                    Ações
                  </th>
                </tr>
              </thead>
              <tbody
                v-if="
                  fastSecretariaCursoDocumentos.loading
                "
                class="text-center"
              >
                <b-icon icon="gear-fill" animation="spin"/> Carregando
              </tbody>
              <tbody
                v-else-if="
                  fastSecretariaCursoDocumentos.total.length
                "
              >
                <tr
                  v-for="(
                    d, index
                  ) in fastSecretariaCursoDocumentos.total"
                  :key="index"
                >
                  <td>
                    {{ d.tipo_documento }}
                  </td>
                  <td>
                    {{ d.descricao_documento }}
                  </td>
                  <td class="text-center">
                    <a
                      class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                      :href="d.path_documento"
                      target="_blank"
                      download
                    > <small>Download</small> </a>
                    <button
                      class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                      @click="
                        exibeModalEditarCursoDocumento(d)
                      "
                    >
                      <small
                        class="text-white"
                      >Editar</small>
                    </button>
                    <button
                      class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                      @click="
                        exibeModalExcluirCursoDocumento(d)
                      "
                    >
                      <small
                        class="text-white"
                      >Excluir</small>
                    </button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td
                    colspan="3"
                    class="text-center"
                  >
                    <span>Nenhum documento cadastrado</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
      </div>
      <template #modal-footer>
        <b-button size="sm" variant="secondary" @click="hideModalBootstrap('modalExibirDocumentosCurso')">
            Fechar 
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modalCadastrarDocumentosCurso" size="xl" scrollable title="Novo arquivo de legalização do curso" >
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
          <label>Tipo de arquivo</label>
          <input type="text" class="form-control" v-model="fastSecretariaCursoDocumentoNovo.tipo_documento">
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
          <label>Descrição</label>
          <input type="text" class="form-control" v-model="fastSecretariaCursoDocumentoNovo.descricao_documento">
        </div>
        <div class="col-12">
          <uploader
            :file-status-text="statusText"
            :options="optionsArquivo"
            class="uploader-example"
            @file-success="fileSuccessDocumentoNovo"
            @file-added="fileValidation"
          >
            <uploader-unsupport />
            <uploader-drop>
              <p class="aluno_font_color">
                Arraste um documento aqui ou
              </p>
              <uploader-btn
                :attrs="restrictArquivo"
              >
                Clique aqui para selecionar
              </uploader-btn>
            </uploader-drop>
            <uploader-list />
          </uploader>
        </div>
      </div>
      <template #modal-footer>
        <b-button size="sm" variant="primary" @click="cadastrarDocumentoCurso()">
            Cadastrar documento
        </b-button>
        <b-button size="sm" variant="secondary" @click="showModalBootstrap('modalExibirDocumentosCurso', 'modalCadastrarDocumentosCurso')">
            Voltar 
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modalEditarCursoDocumento" size="xl" scrollable title="Editar arquivo de legalização do curso" >
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
          <label>Tipo de arquivo</label>
          <input type="text" class="form-control" v-model="fastSecretariaCursoDocumentoEditar.tipo_documento">
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
          <label>Descrição</label>
          <input type="text" class="form-control" v-model="fastSecretariaCursoDocumentoEditar.descricao_documento">
        </div>
        <div class="col-12">
          <uploader
            :file-status-text="statusText"
            :options="optionsArquivo"
            class="uploader-example"
            @file-success="fileSuccessDocumentoEditar"
            @file-added="fileValidation"
          >
            <uploader-unsupport />
            <uploader-drop>
              <p class="aluno_font_color">
                Arraste um documento aqui ou
              </p>
              <uploader-btn
                :attrs="restrictArquivo"
              >
                Clique aqui para selecionar
              </uploader-btn>
            </uploader-drop>
            <uploader-list />
          </uploader>
        </div>
      </div>
      <template #modal-footer>
        <b-button size="sm" variant="primary" @click="editarCursoDocumento()">
            Salvar alterações
        </b-button>
        <b-button size="sm" variant="secondary" @click="showModalBootstrap('modalExibirDocumentosCurso', 'modalEditarCursoDocumento')">
            Voltar 
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modalExcluirCursoDocumento" size="md" scrollable title="Excluir arquivo de legalização do curso" >
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 mb-2">          
          <p class="text-danger">Tem certeza que deseja excluir o documento?</p>
        </div>        
      </div>
      <template #modal-footer>
        <b-button size="sm" variant="danger" @click="excluirCursoDocumento()">
            Confirmar
        </b-button>
        <b-button size="sm" variant="secondary" @click="showModalBootstrap('modalExibirDocumentosCurso', 'modalExcluirCursoDocumento')">
            Voltar 
        </b-button>
      </template>
    </b-modal>

    <notifications
      group="foo"
      :close-on-click="false"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import Pagination from "../components/Pagination";

export default {
  name: "HomeInternoSecretariaCursos",
  components: {
    VueCropper,
    Pagination
  },
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      // Documento
      fastSecretariaCursosTotal: [],
      fastSecretariaCursosFiltro: [],
      fastSecretariaCursosLoading: true,
      pageOfCursos: [],
      fastSecretariaCursoNovo: {
        id_secretaria_curso: 0,
	      nome_curso: "",
	      detalhe_curso: "",
	      publicado: "",
	      carga_horaria: "",
	      logo_curso: "",
	      id_plataforma: 0,
        creditos_total: "",
        nivel: "F",
        preco: 0,
        venda_modulo: "",
        venda_unidade_curricular: "",
        gera_certificado: "",
        possui_aproveitamento: "",
        id_base_curricular: 0,
        loja_virtual: false,
        descricao_legal: ''
      },
      fastSecretariaCursoEditar: {
        id_secretaria_curso: 0,
	      nome_curso: "",
	      detalhe_curso: "",
	      publicado: "",
	      carga_horaria: "",
	      logo_curso: "",
	      id_plataforma: 0,
        creditos_total: "",
        nivel: "",
        preco: 0,
        venda_modulo: "",
        venda_unidade_curricular: "",
        gera_certificado: "",
        possui_aproveitamento: "",
        id_base_curricular: 0,
        loja_virtual: false,
        descricao_legal: ''
      },
      fastSecretariaCursoRequisitos: {},
      fastSecretariaTiposDeDocumento: [],
      fastSecretariaTiposDeDocumentoAtivos: [],
      // Documentos
      fastSecretariaCursoDocumentoNovo: {
        id_secretaria_curso_documento: 0,
        id_plataforma: 0,
        id_secretaria_curso: 0,
        tipo_documento: "",
        descricao_documento: "",
        path_documento: "",        
        carregando: true,
      },
      fastSecretariaCursoDocumentoEditar: {
        id_secretaria_curso_documento: "",
        id_plataforma: 0,
        id_secretaria_curso: 0,
        tipo_documento: "",
        descricao_documento: "",
        path_documento: "",
      },
      fastSecretariaCursoDocumentos: {
        total: [],
        loading: false
      },
      optionsArquivo: {
        target:
          settings.endApiFastEad + "api/fast_secretaria_curso_documento/upload",
        testChunks: false,
        chunkSize: 1024 * 1024 * 100, // 100MB
        singleFile: true,
        query: {
          id_plataforma: this.$route.params.id_plataforma,
          id_secretaria_curso: 0,
        },
        method: "POST",
        headers: { Authorization: "Bearer " + this.retornaToken() },
        categoryMap: {
          document: [
            "pdf",
            "odt",
            "doc",
            "docx",
            "ppt",
            "pptx",
            "gif",
            "jpg",
            "jpeg",
            "png",
          ],
        },
        parseTimeRemaining: function (timeRemaining, parsedTimeRemaining) {
          return parsedTimeRemaining
            .replace(/\syears?/, " anos")
            .replace(/\days?/, " dias")
            .replace(/\shours?/, " horas")
            .replace(/\sminutes?/, " minutos")
            .replace(/\sseconds?/, " segundos");
        },
      },
      restrictArquivo: {
        accept:
          "application/pdf,application/vnd.oasis.opendocument.text,application/msword,application/vnd.ms-powerpoint,image/*",
      },
      statusText: {
        success: "Sucesso",
        error: "Erro",
        uploading: "Fazendo upload...",
        paused: "Upload pausado",
        waiting: "Aguardando...",
      },
      // Filtros
      filtroNiveis: [],
      filtroNivelEscolhido: "",
      // Base curricular
      fastBaseCurricularLoading: true,
      fastBaseCurricular: [],
      fastCursoSufixoNomeClone: "",
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            // Cursos
            this.getSecretariaCursos(this.$route.params.id_plataforma);
            this.getBaseCurricular(this.$route.params.id_plataforma);
            this.getTiposDeDocumento()
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }          
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    } else {
      this.$store.state.fastCarregando = false;
      this.exibeToasty("Você não possui permissões", "error");
    }
  },
  methods: {
    getSecretariaCursos(id_plataforma){
      this.fastSecretariaCursosLoading = true
      this.promiseGetFastApi("api/fast_secretaria_curso/lista", "id_plataforma=" + id_plataforma + "&id_secretaria_curso=0").then(json => {
        let obj = Array.from(json);        
        if (obj.length) {  
          this.fastSecretariaCursosTotal = obj;
          this.fastSecretariaCursosFiltro = obj;
          this.carregaFiltroCursos(obj);
        } else {
          this.fastSecretariaCursosTotal = [];
          this.fastSecretariaCursosFiltro = obj;
        }
        
        this.$store.state.fastCarregando = false
        this.fastSecretariaCursosLoading = false
      }).catch(e => {
        console.log(e);
        this.fastSecretariaCursosLoading = false
      })
    },
    async criarSecretariaCurso() {
      let erros = []
      if (!this.fastSecretariaCursoNovo.nome_curso) erros.push("O nome do curso é obrigatório")
      if (!this.fastSecretariaCursoNovo.carga_horaria) erros.push("A carga horário do curso é obrigatória")
      if (!this.fastSecretariaCursoNovo.nivel) erros.push("O nível do curso é obrigatório")

      if (!this.fastSecretariaCursoNovo.id_base_curricular) this.fastSecretariaCursoNovo.id_base_curricular = 0

      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        const fast_secretaria_curso = {
          nome_curso: this.fastSecretariaCursoNovo.nome_curso,
          id_plataforma: this.$route.params.id_plataforma,
          detalhe_curso: this.fastSecretariaCursoNovo.detalhe_curso ? this.fastSecretariaCursoNovo.detalhe_curso : null,
          publicado: this.fastSecretariaCursoNovo.publicado ? true : false,
          carga_horaria: this.fastSecretariaCursoNovo.carga_horaria ? this.fastSecretariaCursoNovo.carga_horaria : null,
          logo_curso: this.fastSecretariaCursoNovo.logo_curso ? this.fastSecretariaCursoNovo.logo_curso : null,
          creditos_total: this.fastSecretariaCursoNovo.creditos_total ? this.fastSecretariaCursoNovo.creditos_total : null,
          nivel: this.fastSecretariaCursoNovo.nivel,
          preco: this.fastSecretariaCursoNovo.preco ? this.fastSecretariaCursoNovo.preco : null,
          venda_modulo: this.fastSecretariaCursoNovo.venda_modulo ? true : false,
          venda_unidade_curricular: this.fastSecretariaCursoNovo.venda_unidade_curricular ? true : false,
          gera_certificado: this.fastSecretariaCursoNovo.gera_certificado ? true : false,
          possui_aproveitamento: this.fastSecretariaCursoNovo.possui_aproveitamento  ? true : false,
          id_base_curricular: this.fastSecretariaCursoNovo.id_base_curricular,
          loja_virtual: this.fastSecretariaCursoNovo.loja_virtual ? true : false,
          descricao_legal: this.fastSecretariaCursoNovo.descricao_legal
        };
        console.log("fast_secretaria_curso", fast_secretaria_curso)
        this.$store.state.fastCarregando = true;
        this.promiseInserirFastApi(fast_secretaria_curso, "fast_secretaria_curso").then((res) => {
          console.log("criarSecretariaCurso", res)
          this.exibeToasty("Curso criado com sucesso", "success");
          this.hideModal("modalCriarSecretariaCurso");   
          this.fastSecretariaCursosTotal = res;     
          this.fastSecretariaCursosFiltro = res;
          this.carregaFiltroCursos(res);
          this.$store.state.fastCarregando = false;
        }).catch((e) => {
          this.exibeToasty("Erro ao criar requisito", "error");
          this.$store.state.fastCarregando = false;
        });
      }
    },
    exibeModalEditarSecretariaCurso(curso) {
      if (!curso.preco) curso.preco = 0
      this.fastSecretariaCursoEditar = curso;
      this.showModal("modalEditarSecretariaCurso");

      this.fastSecretariaCursoDocumentos.loading = true
      this.optionsArquivo.query.id_secretaria_curso = curso.id_secretaria_curso
      this.fastSecretariaCursoDocumentoNovo.id_secretaria_curso = curso.id_secretaria_curso
      this.promiseGetFastApi("api/fast_secretaria_curso_documento/lista", "id_plataforma=" + this.$route.params.id_plataforma + "&id_secretaria_curso=" + curso.id_secretaria_curso).then(json => {
        let obj = Array.from(json);        
        if (obj.length) {  
          this.fastSecretariaCursoDocumentos.total = obj
        } else {
          this.fastSecretariaCursoDocumentos.total = []
        }        
        this.fastSecretariaCursoDocumentos.loading = false
      }).catch(e => {
        console.log(e);
        this.fastSecretariaCursoDocumentos.loading = false
      })
    },
    async getRequisitosDeMatricula(curso) {
      this.$store.state.fastCarregando = true
      this.promiseGetFastApi("api/fast_secretaria_matricula_requisito/lista", "id_plataforma=" + this.$route.params.id_plataforma + "&id_secretaria_curso=" + curso.id_secretaria_curso)
      .then(res => {
        // console.log("res",res)
        if (res) {
          if(res.success === false){
            console.log(res);
            this.exibeToasty("Ocorreu um erro", "error")
          } else {
            this.fastSecretariaCursoRequisitos = res;
            this.verificaDocsAtivosNosRequisitos()
          }
          this.$store.state.fastCarregando = false
        }
      })
      .catch((err) => {
        this.$store.state.fastCarregando = false
        console.log(err);
        this.exibeToasty(err, "error")
      })
    },
    async cadastraRequisitosDeFormulario(){
      this.$store.state.fastCarregando = true
      let obj = {
        id_plataforma: this.$route.params.id_plataforma,
        id_secretaria_curso: this.fastSecretariaCursoRequisitos.id_secretaria_curso,
        cpf_cnpj: this.fastSecretariaCursoRequisitos.cpf_cnpj,
        nome_razao: this.fastSecretariaCursoRequisitos.nome_razao,
        sobrenome_fantasia: this.fastSecretariaCursoRequisitos.sobrenome_fantasia,
        datanascimento_abertura: this.fastSecretariaCursoRequisitos.datanascimento_abertura,
        sexo: this.fastSecretariaCursoRequisitos.sexo,
        id_pais_pessoa: this.fastSecretariaCursoRequisitos.id_pais_pessoa,
        naturalidade: this.fastSecretariaCursoRequisitos.naturalidade,
        rg: this.fastSecretariaCursoRequisitos.rg,
        orgao_emissor: this.fastSecretariaCursoRequisitos.orgao_emissor,
        uf: this.fastSecretariaCursoRequisitos.uf,
        cep: this.fastSecretariaCursoRequisitos.cep,
        logradouro: this.fastSecretariaCursoRequisitos.logradouro,
        bairro: this.fastSecretariaCursoRequisitos.bairro,
        cidade: this.fastSecretariaCursoRequisitos.cidade,
        complemento: this.fastSecretariaCursoRequisitos.complemento,
        numero: this.fastSecretariaCursoRequisitos.numero,
        id_pais_endereco: this.fastSecretariaCursoRequisitos.id_pais_endereco,
        telefone: this.fastSecretariaCursoRequisitos.telefone,
        id_pais_telefone: this.fastSecretariaCursoRequisitos.id_pais_telefone
      }
      // console.log("obj", obj);
        this.promisePostFastApi(obj, "api/fast_secretaria_matricula_requisito/atualiza")
        .then((res) => {
          if(res.success === false){
            console.log(res);
            this.exibeToasty("Ocorreu um erro", "error")
          } else {
            this.exibeToasty("Alteração realizada com sucesso", "success")
            this.getRequisitosDeMatricula(this.fastSecretariaCursoEditar)
          }
          this.$store.state.fastCarregando = false;
        }).catch((e) => {
          console.log(e);
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    },
    exibeModalAlteraRequisitosDeMatricula(curso){
      this.hideModal('modalEditarSecretariaCurso')
      this.getRequisitosDeMatricula(curso)
      this.showModal('modalAlterarRequisitosMatricula')
    },
    async getTiposDeDocumento(){
      this.promiseGetFastApi("api/fast_secretaria_tipo_documento/lista", "id_plataforma=" + this.$route.params.id_plataforma + "&id_secretaria_curso=")
      .then(res => {
        // console.log("res",res)
        if(res.success === false){
            console.log(res);
            this.exibeToasty("Ocorreu um erro", "error")
        } else {
          this.fastSecretariaTiposDeDocumento = res;
        }
      })
      .catch((err) => {
        console.log(err);
        this.exibeToasty(err, "error")
      })
    },
    verificaDocsAtivosNosRequisitos(){
      this.fastSecretariaTiposDeDocumentoAtivos = this.fastSecretariaTiposDeDocumento.map((tipo_doc) => {
        const requisitoCorrespondente = this.fastSecretariaCursoRequisitos.fast_secretaria_matricula_requisito_documento.find(
          (requisito) => requisito.tipo_documento === tipo_doc.tipo_documento
        );
        if (requisitoCorrespondente) {
          const novoTipoDoc = { ...tipo_doc, ativo: true }; 
          if (requisitoCorrespondente.obrigatorio) {
            novoTipoDoc.obrigatorio = true;
          }
          return novoTipoDoc;
        }
        return tipo_doc;
      });
    },
    async ativaDocumentoRequisito(documento){
      this.$store.state.fastCarregando = true;
      let obj = {
          id_matricula_requisito: this.fastSecretariaCursoRequisitos.id_matricula_requisito,
          id_plataforma: this.$route.params.id_plataforma,
          tipo_documento: documento.tipo_documento,
          obrigatorio: documento.obrigatorio ? true : false
      }
      this.promisePostFastApi(obj, "api/fast_secretaria_matricula_requisito/insere_documento")
        .then((res) => {
          if(res.success === false){
            console.log(res);
            this.exibeToasty("Ocorreu um erro", "error")
          } else {
            this.exibeToasty("Alteração realizada com sucesso", "success")
            this.getRequisitosDeMatricula(this.fastSecretariaCursoEditar)
          }
          this.$store.state.fastCarregando = false;
        }).catch((e) => {
          console.log(e);
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    },
    async atualizaDocumentoRequisito(documento){
      this.$store.state.fastCarregando = true;
      
      let obj = {
        id_matricula_requisito: this.fastSecretariaCursoRequisitos.id_matricula_requisito,
        id_plataforma: this.$route.params.id_plataforma,
        tipo_documento: documento.tipo_documento,
        obrigatorio: documento.obrigatorio
      }
      console.log("obj", obj);
      this.promisePostFastApi(obj, "api/fast_secretaria_matricula_requisito/atualiza_documento")
        .then((res) => {
          if(res.success === false){
            console.log(res);
            this.exibeToasty("Ocorreu um erro", "error")
          } else {
            this.exibeToasty("Alteração realizada com sucesso", "success")
            this.getRequisitosDeMatricula(this.fastSecretariaCursoEditar)
          }
          this.$store.state.fastCarregando = false;
        }).catch((e) => {
          console.log(e);
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    },
    async inativaDocumentoRequisito(documento){
      this.$store.state.fastCarregando = true;
      let obj = {
        id_matricula_requisito: this.fastSecretariaCursoRequisitos.id_matricula_requisito,
        id_plataforma: this.$route.params.id_plataforma,
        tipo_documento: documento.tipo_documento,
      }
      this.promisePostFastApi(obj, "api/fast_secretaria_matricula_requisito/exclui_documento")
        .then((res) => {
          if(res.success === false){
            console.log(res);
            this.exibeToasty("Ocorreu um erro", "error")
          } else {
            this.exibeToasty("Alteração realizada com sucesso", "success")
            this.getRequisitosDeMatricula(this.fastSecretariaCursoEditar)
          }
          this.$store.state.fastCarregando = false;
        }).catch((e) => {
          console.log(e);
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    },
    async editarSecretariaCurso() {
      let erros = []
      if (!this.fastSecretariaCursoEditar.nome_curso) erros.push("O nome do curso é obrigatório")
      if (!this.fastSecretariaCursoEditar.carga_horaria) erros.push("A carga horário do curso é obrigatória")
      if (!this.fastSecretariaCursoEditar.nivel) erros.push("O nível do curso é obrigatório")

      if (!this.fastSecretariaCursoEditar.id_base_curricular) this.fastSecretariaCursoEditar.id_base_curricular = 0

      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        const fast_secretaria_curso = {
          id_secretaria_curso: this.fastSecretariaCursoEditar.id_secretaria_curso,
          nome_curso: this.fastSecretariaCursoEditar.nome_curso,
          id_plataforma: this.$route.params.id_plataforma,
          detalhe_curso: this.fastSecretariaCursoEditar.detalhe_curso ? this.fastSecretariaCursoEditar.detalhe_curso : null,
          publicado: this.fastSecretariaCursoEditar.publicado,
          carga_horaria: this.fastSecretariaCursoEditar.carga_horaria ? this.fastSecretariaCursoEditar.carga_horaria : null,
          logo_curso: this.fastSecretariaCursoEditar.logo_curso ? this.fastSecretariaCursoEditar.logo_curso : null,
          creditos_total: this.fastSecretariaCursoEditar.creditos_total ? this.fastSecretariaCursoEditar.creditos_total : null,
          nivel: this.fastSecretariaCursoEditar.nivel,
          preco: this.fastSecretariaCursoEditar.preco ? this.fastSecretariaCursoEditar.preco : null,
          venda_modulo: this.fastSecretariaCursoEditar.venda_modulo ? true : false,
          venda_unidade_curricular: this.fastSecretariaCursoEditar.venda_unidade_curricular ? true : false,
          gera_certificado: this.fastSecretariaCursoEditar.gera_certificado ? true : false,
          possui_aproveitamento: this.fastSecretariaCursoEditar.possui_aproveitamento ? true : false,
          id_base_curricular: this.fastSecretariaCursoEditar.id_base_curricular,
          loja_virtual: this.fastSecretariaCursoEditar.loja_virtual ? true : false,
          descricao_legal: this.fastSecretariaCursoEditar.descricao_legal
        };      

        console.log("fast_secretaria_curso", fast_secretaria_curso)
        this.$store.state.fastCarregando = true;
          this.promiseAtualizarFastApi(fast_secretaria_curso, "fast_secretaria_curso").then(e => {
            console.log("promiseAtualizarFastApi", e)
            this.exibeToasty("Salvo com sucesso", "success");          
            this.$store.state.fastCarregando = false;
            this.hideModal("modalEditarSecretariaCurso");
          }).catch(e => {
            this.exibeToasty("Erro ao salvar alterações", "error");
            this.$store.state.fastCarregando = false;
          }
          );
      }
      
    },
    exibeModalExcluirSecretariaCurso(curso) {
      this.$store.state.fastCarregando = true;
      let turmasCurso = 0
      this.promiseGetFastApi("api/fast_secretaria_turma/lista", "id_plataforma=" + this.$route.params.id_plataforma + "&id_turma=0")
      .then(json => {        
          let obj = Array.from(json);  
          if (obj.length)  turmasCurso = obj.filter(e =>  e.id_secretaria_curso === curso.id_secretaria_curso) 
          return turmasCurso.length              
      })
      .then((turmasCurso) => {
        this.$store.state.fastCarregando = false;
        if (turmasCurso) {
          console.log(turmasCurso)
          this.exibeToasty(`Esse curso possui ${turmasCurso} turmas associadas`, "error");
        } else {
          this.fastSecretariaCursoEditar = curso;
          this.showModal("modalExcluirSecretariaCurso");
        }
      })
      .catch(e => {
        this.$store.state.fastCarregando = false;
        console.log(e);
        this.exibeToasty(e, "error");
      })
    },
    async excluirSecretariaCurso(){
      this.$store.state.fastCarregando = true;
      this.promiseExcluirFastApi(this.fastSecretariaCursoEditar, "fast_secretaria_curso").then(e => {
        this.$store.state.fastCarregando = false;
        this.exibeToasty("Curso excluído com sucesso", "success");        
        this.getSecretariaCursos(this.$route.params.id_plataforma);   
        this.hideModal("modalExcluirSecretariaCurso");
      }).catch(e => {
        this.exibeToasty("Erro ao excluir", "error");
        this.$store.state.fastCarregando = false;
      });
    },
    // Base curricular
    async getBaseCurricular(id_plataforma){
      this.fastBaseCurricularLoading = true
      this.promiseGetFastApi("api/projeto_base_curricular/lista", "id_plataforma=" + id_plataforma + "&id_base_curricular=0").then(obj => {
        if (obj.length) this.fastBaseCurricular = obj
        else    
            this.fastBaseCurricular = [];
            console.log("base", this.fastBaseCurricular)
        this.fastBaseCurricularLoading = false
      }).catch(e => { 
        this.exibeToasty("Erro ao buscar base curricular", "error");
        this.fastBaseCurricularLoading = false
      })
    },
    // Image cropper
    setImageLogoCursoNovo(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (file.size / 1024 > 1024) {
        alert("Não utilize imagens acima de 1MB");
        return;
      }

      this.showModal("modalImageCropLogoCursoNovo");

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.fastSecretariaCursoNovo.logo_curso = event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    setImageLogoCursoEditar(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (file.size / 1024 > 1024) {
        alert("Não utilize imagens acima de 1MB");
        return;
      }

      this.showModal("modalImageCropLogoCursoEditar");

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.fastSecretariaCursoEditar.logo_curso = event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    cropImageNovo() {
        this.fastSecretariaCursoNovo.logo_curso = this.$refs.cropper
          .getCroppedCanvas()
          .toDataURL();
        this.hideModal("modalImageCropLogoCursoNovo");      
    },
    cropImageEditar() {
        this.fastSecretariaCursoEditar.logo_curso = this.$refs.cropper
          .getCroppedCanvas()
          .toDataURL();
        this.hideModal("modalImageCropLogoCursoEditar");      
    },
    retornaCursoNivel(nivel) {
      switch (nivel) {
        case 'F':
          return 'Ensino fundamental'
          break;
        case 'E':
          return 'Ensino médio'
          break;
        case 'J':
          return 'EJA'
          break;
        case 'T':
          return 'Técnico'
          break;        
        case 'S':
          return 'Ensino superior'
          break;
        case 'P':
          return 'Pós-graduação'
          break;
        case 'M':
          return 'Mestrado'
          break;
        case 'D':
          return 'Doutorado'
          break;        
        default:
          break;
      }
    },
    filtroNomeCurso(e) {
      let text = e.target.value;
      this.fastSecretariaCursosFiltro = this.fastSecretariaCursosTotal.filter(
        (e) => {
          return (
            e.nome_curso.toLowerCase().indexOf(text.toLowerCase()) != "-1"
          );
        }
      );
    },
    aplicaFiltroCursos() {
      this.fastSecretariaCursosFiltro = this.fastSecretariaCursosTotal.filter(
        (e) => {
          if (this.filtroNivelEscolhido) {
            return e.nivel == this.filtroNivelEscolhido;
          } else {
            return true;
          }
        }
      );
    },
    carregaFiltroCursos(obj) {
      this.filtroNiveis = []      
      obj.forEach((e) => {
        this.filtroNiveis.push(e.nivel);
      });
      // Remove valores duplicados do array
      this.filtroNiveis = this.filtroNiveis.filter(
        (a, b) => this.filtroNiveis.indexOf(a) === b
      );
    },
    //#region Documentos do curso
    exibeModalDocumentosCurso(id_secretaria_curso){
      this.hideModal('modalEditarSecretariaCurso')
      this.showModalBootstrap('modalExibirDocumentosCurso')
    },
    exibeModalEditarCursoDocumento(d) {
      this.fastSecretariaCursoDocumentoEditar = d
      this.showModalBootstrap('modalEditarCursoDocumento', 'modalExibirDocumentosCurso')
    },
    async editarCursoDocumento(){
      let erros = []
      if (!this.fastSecretariaCursoDocumentoEditar.tipo_documento) erros.push("O tipo é obrigatório")
      if (!this.fastSecretariaCursoDocumentoEditar.descricao_documento) erros.push("A descrição é obrigatória")
      if (!this.fastSecretariaCursoDocumentoEditar.path_documento) erros.push("O arquivo é obrigatório")

      if (!this.fastSecretariaCursoDocumentoEditar.id_plataforma) this.fastSecretariaCursoDocumentoNovo.id_plataforma = this.$route.params.id_plataforma

      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        this.$store.state.fastCarregando = true
        this.promisePostFastApi(this.fastSecretariaCursoDocumentoEditar, "api/fast_secretaria_curso_documento/atualiza")
        .then((res) => {
          console.log("editarCursoDocumento", res)
          this.exibeToasty("Documento salvo com sucesso", "success");
          this.fastSecretariaCursoDocumentos.total = res;  
          this.fastSecretariaCursoDocumentoEditar.tipo_documento = ''
          this.fastSecretariaCursoDocumentoEditar.descricao_documento = ''
          this.fastSecretariaCursoDocumentoEditar.path_documento = ''
          this.showModalBootstrap('modalExibirDocumentosCurso', 'modalEditarCursoDocumento')
          this.$store.state.fastCarregando = false;
        }).catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
      }
    },
    exibeModalExcluirCursoDocumento(d) {
      this.fastSecretariaCursoDocumentoEditar = d
      this.showModalBootstrap('modalExcluirCursoDocumento', 'modalExibirDocumentosCurso')
    },
    async excluirCursoDocumento(){
      this.$store.state.fastCarregando = true
      this.promisePostFastApi(this.fastSecretariaCursoDocumentoEditar, "api/fast_secretaria_curso_documento/exclui")
      .then((res) => {
        console.log("excluirDocumentoCurso", res)
        this.exibeToasty("Documento excluído com sucesso", "success");
        this.fastSecretariaCursoDocumentos.total = res;  
        this.fastSecretariaCursoDocumentoEditar.tipo_documento = ''
        this.fastSecretariaCursoDocumentoEditar.descricao_documento = ''
        this.fastSecretariaCursoDocumentoEditar.path_documento = ''
        this.showModalBootstrap('modalExibirDocumentosCurso', 'modalExcluirCursoDocumento')
        this.$store.state.fastCarregando = false;
      }).catch((e) => {
        this.exibeToasty(e, "error");
        this.$store.state.fastCarregando = false;
      });
    },
    fileValidation(e) {
      return false;
    },
    fileSuccessDocumentoNovo(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));
      console.log(result)
      if (!result.error) {
        this.fastSecretariaCursoDocumentoNovo.path_documento = result.url        
      } else {
        this.exibeToasty(result.error, "error")
        this.fastSecretariaCursoDocumentoNovo.path_documento = ''
      }
    },
    fileSuccessDocumentoEditar(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));
      console.log(result)
      if (!result.error) {
        this.fastSecretariaCursoDocumentoEditar.path_documento = result.url
      } else {
        this.exibeToasty(result.error, "error")
        this.fastSecretariaCursoDocumentoEditar.path_documento = ''
      }
    },
    async cadastrarDocumentoCurso(){
      let erros = []
      if (!this.fastSecretariaCursoDocumentoNovo.tipo_documento) erros.push("O tipo é obrigatório")
      if (!this.fastSecretariaCursoDocumentoNovo.descricao_documento) erros.push("A descrição é obrigatória")
      if (!this.fastSecretariaCursoDocumentoNovo.path_documento) erros.push("O arquivo é obrigatório")

      if (!this.fastSecretariaCursoDocumentoNovo.id_plataforma) this.fastSecretariaCursoDocumentoNovo.id_plataforma = this.$route.params.id_plataforma

      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        this.$store.state.fastCarregando = true
        this.promisePostFastApi(this.fastSecretariaCursoDocumentoNovo, "api/fast_secretaria_curso_documento/insere")
        .then((res) => {
          console.log("cadastrarDocumentoCurso", res)
          this.exibeToasty("Documento criado com sucesso", "success");
          this.fastSecretariaCursoDocumentos.total = res;  
          this.fastSecretariaCursoDocumentoNovo.tipo_documento = ''
          this.fastSecretariaCursoDocumentoNovo.descricao_documento = ''
          this.fastSecretariaCursoDocumentoNovo.path_documento = ''
          this.showModalBootstrap('modalExibirDocumentosCurso', 'modalCadastrarDocumentosCurso')
          this.$store.state.fastCarregando = false;
        }).catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
      }
    },
    //#endregion
    exibeModalClonarCurso() {
      this.hideModal('modalEditarSecretariaCurso')
      this.showModal('modalClonarCurso')
    },
    async clonarCurso(){
      this.$store.state.fastCarregando = true
      const clonarCurso = {
        id_plataforma: this.$route.params.id_plataforma,
        id_secretaria_curso: this.fastSecretariaCursoEditar.id_secretaria_curso,
        sufixo: this.fastCursoSufixoNomeClone
      }
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_secretaria_curso/clonar",
          this.fastAjaxOptions("POST", JSON.stringify(clonarCurso))
        );
        if(resp.success === false){
          console.log(`${resp.error} ${resp.description ? resp.description : ""}`)
          this.exibeToasty(`${resp.error} ${resp.description ? resp.description : ""}`, "error")
          this.$store.state.fastCarregando = false 
        } else {
          this.hideModal('modalClonarCurso')
          this.exibeToasty("Alterações salvas", "success")
          this.getSecretariaCursos(this.$route.params.id_plataforma);
          const data = await resp.json();
          window.location.href = '/plataforma/' + this.$route.params.id_plataforma + '/secretaria/curso/' + data.Id_secretaria_curso;
        }
      } catch (err) {
        this.exibeToasty("Ocorreu um erro", "error")
        console.error(err);
        this.$store.state.fastCarregando = false 
      }
    },
  },
};
</script>

<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}

.message-field-label {
    display: flex;
    align-items: left;
    font-size: 12px;
    color:#495057;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
</style>
