<template>
  <div v-if="$store.state.fastPermissoes.administrador == 'S' && $store.state.fastPermissoes.ativo == 'S'">
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="
                  '/plataforma/' +
                    $route.params.id_plataforma +
                    '/secretaria/unidades-curriculares'
                "
                @click.prevent="$router.push('/plataforma/' +
                  $route.params.id_plataforma +
                  '/secretaria/unidades-curriculares')"
              >Unidades curriculares</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Gerenciamento da UC <span v-if="fast_curso.nome_curso">{{ fast_curso.nome_curso }}</span>
                  <span v-else>{{ fast_curso.nome_curso_mae }}</span></a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Gerenciamento da UC
                  <span v-if="fast_curso.nome_curso">{{ fast_curso.nome_curso }}</span>
                  <span v-else>{{ fast_curso.nome_curso_mae }}</span>
                </h1>
                <img :src="require('@/assets/images/form-effect-01.png')">
                <p
                  v-if="
                    $route.params.id_plataforma !=
                      fast_curso.id_plataforma_origem
                  "
                  class="text-info"
                >
                  UC herdada da plataforma mãe
                </p>
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div
                  class="col-sm-12 col-md-12 col-lg-12 mt-4 mb-4 pr-0 text-right"
                >
                  <button
                    v-if="
                      $route.params.id_plataforma ==
                        fast_curso.id_plataforma_origem
                    "
                    class="btn btn-sm btn-danger ml-2 mr-2"
                    @click="showModal('modalExcluirFastCurso')"
                  >
                    <small>Excluir unidade curricular</small>
                  </button>
                  <a
                    class="btn btn-primary ml-2 mr-2"
                    :href="
                      '/plataforma/' +
                        $route.params.id_plataforma +
                        '/secretaria/unidade-curricular/' +
                        $route.params.id_curso +
                        '/aulas'
                    "
                    @click.prevent="$router.push('/plataforma/' +
                      $route.params.id_plataforma +
                      '/secretaria/unidade-curricular/' +
                      $route.params.id_curso +
                      '/aulas')"
                  >Gerenciar aulas</a>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <tabs :options="{ useUrlFragment: false }">
                    <tab name="Geral">
                      <h4 class="mb-4">
                        Geral
                      </h4>
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <label>Titulo da UC</label>
                          <input
                            v-model="fast_curso.nome_curso"
                            class="form-control form-control-lg"
                            type="text"
                            placeholder="Nome do curso"
                          >
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                          <label>Detalhes</label>
                          <textarea
                            v-model="fast_curso.detalhe_curso"
                            class="form-control form-control-lg"
                            placeholder="Detalhes da UC"
                            maxlength="255"
                            style="
                              margin-top: 0px;
                              margin-bottom: 0px;
                              height: 161px;
                            "
                          />
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4 mt-4">
                          <span
                            v-tooltip.bottom-start="{
                              content: exibeTooltip('carga_horaria'),
                            }"
                            class="mr-1"
                          >
                            <b-icon-question-circle />
                          </span>
                          <label>Carga horária:</label>

                          <input                           
                            v-model="fast_curso.carga_horaria"
                            class="form-control form-control-lg"
                            type="number"
                            min="1"
                            max="50"
                          >
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4 mt-4">
                          <span
                            v-tooltip.bottom-start="{
                              content: exibeTooltip('reiniciar_curso'),
                            }"
                            class="mr-1"
                          >
                            <b-icon-question-circle />
                          </span>
                          <span>Reiniciar unidade curricular:</span>

                          <select
                            v-model="fast_curso.reiniciar"
                            class="form-control form-control-lg mt-2"
                          >
                            <option value="0">
                              Nenhuma
                            </option>
                            <option value="1">
                              1 vezes
                            </option>
                            <option value="2">
                              2 vezes
                            </option>
                            <option value="3">
                              3 vezes
                            </option>
                            <option value="4">
                              4 vezes
                            </option>
                            <option value="5">
                              5 vezes
                            </option>
                            <option value="6">
                              6 vezes
                            </option>
                            <option value="7">
                              7 vezes
                            </option>
                            <option value="8">
                              8 vezes
                            </option>
                            <option value="9">
                              9 vezes
                            </option>
                            <option value="10">
                              10 vezes
                            </option>
                            <option value="99999">
                              Ilimitado
                            </option>
                          </select>
                        </div>  
                        
                        <div class="col-sm-12 col-md-12 col-lg-4 mt-4">
                          <div class="d-flex align-items-center">
                            <span
                              v-tooltip.bottom-start="{
                                content: exibeTooltip('curso_publicado'),
                              }"
                              class="mr-1"
                            >
                              <b-icon-question-circle />
                            </span>
                            <label>UC Publicada</label>
                            <div class="fd-app-meus-cursos mt-0 ml-1">
                              <div class="fd-app-curso-aulas-list mt-0">
                                <div
                                  class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                                >
                                  <label class="switch">
                                    <input
                                      v-if="fast_curso.publicado"
                                      v-model="fast_curso.publicado"
                                      checked="checked"
                                      type="checkbox"
                                    >
                                    <input
                                      v-else
                                      v-model="fast_curso.publicado"
                                      type="checkbox"
                                    >
                                    <span class="slider round" />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>                      
                        <div
                          class="col-sm-12 col-md-12 col-lg-12 text-center mt-4 pt-4"
                        >
                          <button
                            class="btn btn-primary"
                            @click="alterarFastCurso()"
                          >
                            SALVAR ALTERAÇÕES
                          </button>
                        </div>
                      </div>
                    </tab>
                    <tab
                      name="Logotipo"
                    >
                      <h4 class="mb-4">
                        Logotipo da UC
                      </h4>
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                          <div class="row p-3">
                            <div class="col-12 card p-4">
                              <strong>Logo da UC</strong>
                              <div
                                v-if="fast_curso.logo_curso"
                                class="mt-2 mb-2"
                              >
                                <img
                                  :src="ajustaLinkApiAntiga(fast_curso.logo_curso)"
                                  style="max-width: 100%"
                                >
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-12 mt-4 p-0">
                                <!-- upload aqui -->
                                <input
                                  id="logo_plataforma"
                                  type="file"
                                  class="custom-file-input"
                                  accept="image/*"
                                  @change="setImageLogoCurso"
                                >
                                <label class="message-field-label mt-2">Dimensões sugeridas: 952 x 519 pixels</label>
                               <label
                                  class="custom-file-label"
                                  for="logo_plataforma"
                                >Selecione uma imagem...</label>
                                <div class="invalid-feedback">
                                  Arquivo inválido
                                </div>
                                <modal
                                  name="modalImageCropLogoCurso"
                                  height="auto"
                                  :shift-y="0.1"
                                  :focus-trap="true"
                                  :adaptive="true"
                                >
                                  <div class="row">
                                    <div class="col-12 pt-4 text-center">
                                      <h4>Recorte a imagem</h4>
                                    </div>
                                    <div
                                      class="col-sm-12 col-md-12 col-lg-12 p-4 text-center"
                                    >
                                      <vue-cropper
                                        ref="cropper"
                                        :src="fast_curso.logo_curso"
                                        :auto-crop-area="1"
                                        :aspect-ratio="159 / 85"
                                      />
                                      <div class="text-center mt-2">
                                        <a
                                          class="btn btn-primary"
                                          href="#"
                                          role="button"
                                          @click.prevent="
                                            cropImage('LogoCurso')
                                          "
                                        >Recortar</a>
                                      </div>
                                    </div>
                                  </div>
                                </modal>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </tab>
                    <tab
                      v-if="
                        $route.params.id_plataforma ==
                          fast_curso.id_plataforma_origem
                      "
                      name="Disciplinas"
                      :suffix="
                        '<span class=&quot;suffix&quot;>' +
                          (fast_curso.disciplinas
                            ? fast_curso.disciplinas.length
                            : '0') +
                          '</span>'
                      "
                    >
                      <div class="row mb-4">
                        <div class="col-sm-12 col-md-12 col-lg-8">
                          <h4>Disciplinas</h4>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4 text-right">
                          <button
                            class="btn btn-sm btn-primary mt-2"
                            @click="showModal('modalEscolherDisciplina')"
                          >
                            <small>+ Adicionar disciplinas</small>
                          </button>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                          <table
                            v-if="fast_curso.disciplinas"
                            class="table table-striped table-bordered"
                          >
                            <thead>
                              <tr class="bg-dark text-white">
                                <th scope="col">
                                  Nome da disciplina
                                </th>
                                <th
                                  class="text-center"
                                  scope="col"
                                >
                                  Opções
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="fast_curso.disciplinas.length">
                              <tr
                                v-for="disciplina in fast_curso.disciplinas"
                                :key="disciplina.id_plataforma_area_disciplina"
                              >
                                <td>{{ disciplina.descricao }}</td>
                                <td class="text-center">
                                  <button
                                    class="btn btn-danger btn-sm mt-2 ml-2"
                                    @click="excluirDisciplinaCurso(disciplina)"
                                  >
                                    <small>
                                      <i
                                        class="fa fa-ban"
                                        aria-hidden="true"
                                      />
                                      &nbsp; Excluir
                                    </small>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td
                                  colspan="2"
                                  class="text-center"
                                >
                                  <h6>Nenhuma disciplina encontrada</h6>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </tab>
                    <tab
                      v-if="
                        $route.params.id_plataforma ==
                          fast_curso.id_plataforma_origem
                      "
                      name="Segmento"
                      :suffix="
                        '<span class=&quot;suffix&quot;>' +
                          (fast_curso.segmentos
                            ? fast_curso.segmentos.length
                            : '0') +
                          '</span>'
                      "
                    >
                      <div class="row mb-4">
                        <div class="col-sm-12 col-md-12 col-lg-8">
                          <h4>Segmentos</h4>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4 text-right">
                          <button
                            class="btn btn-sm btn-primary mt-2"
                            @click="showModal('modalEscolherSegmento')"
                          >
                            <small>+ Adicionar segmentos</small>
                          </button>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                          <table
                            v-if="fast_curso.segmentos"
                            class="table table-striped table-bordered"
                          >
                            <thead>
                              <tr class="bg-dark text-white">
                                <th scope="col">
                                  Nome do segmento
                                </th>
                                <th
                                  class="text-center"
                                  scope="col"
                                >
                                  Opções
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="fast_curso.segmentos.length">
                              <tr
                                v-for="segmento in fast_curso.segmentos"
                                :key="segmento.id_segmento"
                              >
                                <td>{{ segmento.nome_segmento }}</td>
                                <td class="text-center">
                                  <button
                                    class="btn btn-danger btn-sm mt-2 ml-2"
                                    @click="excluirSegmentoCurso(segmento)"
                                  >
                                    <small>
                                      <i
                                        class="fa fa-ban"
                                        aria-hidden="true"
                                      />
                                      &nbsp; Excluir
                                    </small>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td
                                  colspan="2"
                                  class="text-center"
                                >
                                  <h6>Nenhum segmento encontrada</h6>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </tab>
                    <tab
                      v-if="
                        $route.params.id_plataforma ==
                          fast_curso.id_plataforma_origem
                      "
                      name="Tags"
                      :suffix="
                        '<span class=&quot;suffix&quot;>' +
                          (fast_curso.tags ? fast_curso.tags.length : '0') +
                          '</span>'
                      "
                    >
                      <div class="row mb-4">
                        <div class="col-sm-12 col-md-12 col-lg-8">
                          <h4>Tags</h4>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4 text-right">
                          <button
                            class="btn btn-sm btn-primary mt-2"
                            @click="showModal('modalEscolherTag')"
                          >
                            <small>+ Adicionar tag</small>
                          </button>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                          <table
                            v-if="fast_curso.tags"
                            class="table table-striped table-bordered"
                          >
                            <thead>
                              <tr class="bg-dark text-white">
                                <th scope="col">
                                  Nome da tag
                                </th>
                                <th
                                  class="text-center"
                                  scope="col"
                                >
                                  Opções
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="fast_curso.tags.length">
                              <tr
                                v-for="tag in fast_curso.tags"
                                :key="tag.nome_tag"
                              >
                                <td>{{ tag.nome_tag }}</td>
                                <td class="text-center">
                                  <button
                                    class="btn btn-danger btn-sm mt-2 ml-2"
                                    @click="excluirTagCurso(tag)"
                                  >
                                    <small>
                                      <i
                                        class="fa fa-ban"
                                        aria-hidden="true"
                                      />
                                      &nbsp; Excluir
                                    </small>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td
                                  colspan="2"
                                  class="text-center"
                                >
                                  <h6>Nenhuma tag encontrada</h6>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </tab>
                    <tab
                      v-if="
                        $route.params.id_plataforma ==
                          fast_curso.id_plataforma_origem
                      "
                      name="Professores"
                      :suffix="
                        '<span class=&quot;suffix&quot;>' +
                          professoresCursoTotal.length +
                          '</span>'
                      "
                    >
                      <h4 class="mb-4">
                        Professores da UC
                      </h4>
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-4 pb-2">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Filtrar por nome"
                            @keyup="filtraProfessorCurso"
                          >
                        </div>
                        <div
                          class="col-sm-12 col-md-12 col-lg-8 pb-2 text-right"
                        >
                          <button
                            class="btn btn-sm btn-primary mt-2"
                            @click="showModal('modalEscolherProfessor')"
                          >
                            <small>+ Adicionar professor à UC</small>
                          </button>
                        </div>
                        <div
                          class="col-sm-12 col-md-12 col-lg-12 table-responsive"
                        >
                          <table class="table table-striped">
                            <thead class="thead-dark">
                              <tr>
                                <th scope="col" />
                                <th scope="col">
                                  Nome
                                </th>
                                <th scope="col">
                                  Email
                                </th>
                                <th
                                  class="text-center"
                                  scope="col"
                                >
                                  Tutor
                                </th>
                                <th
                                  class="text-center"
                                  scope="col"
                                >
                                  Professor
                                </th>
                                <th
                                  class="text-center"
                                  scope="col"
                                >
                                  Ações
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="prof in pageOfProfessoresCurso"
                                :key="prof.id_professor"
                              >
                                <td scope="row">
                                  <div
                                    :style="{
                                      backgroundImage:
                                        'url(' +
                                        ajustaLinkImageUser(prof.image) +
                                        ')',
                                      width: '60px',
                                      height: '60px',
                                      margin: 'auto',
                                      marginBottom: '5px',
                                      borderRadius: '50%',
                                      backgroundSize: '100%',
                                      backgroundRepeat: 'no-repeat',
                                      backgroundPosition: '50%',
                                    }"
                                  />
                                </td>
                                <td class="align-middle">
                                  {{ prof.first_name + " " + prof.last_name }}
                                </td>
                                <td class="align-middle">
                                  {{ prof.email }}
                                </td>
                                <td class="align-middle text-center">
                                  <i
                                    v-if="prof.tutor"
                                    class="fas fa-check-circle text-success fa-2x"
                                  />
                                </td>
                                <td class="align-middle text-center">
                                  <i
                                    v-if="!prof.tutor"
                                    class="fas fa-check-circle text-success fa-2x"
                                  />
                                </td>
                                <td class="align-middle text-center">
                                  <button
                                    class="btn btn-sm btn-danger btn-blocke"
                                    title="Remover como tutor desta UC"
                                    @click="
                                      excluiProfessorCurso(prof.id_professor)
                                    "
                                  >
                                    <small>
                                      <b-icon-person-dash-fill /> Remover
                                    </small>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-12 text-center mt-4">
                          <Pagination
                            :page-size="10"
                            :items="professoresCursoFiltro"
                            @changePage="pageOfProfessoresCurso = $event"
                          />
                        </div>
                      </div>
                    </tab>
                    <tab
                      v-if="fast_curso.acesso == 'R'"
                      name="Restrito"
                      :is-disabled="true"
                    >
                      <h4 class="mb-4">
                        Restrições
                      </h4>
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-4 pb-2">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Filtrar por nome"
                            @keyup="filtraPlataformaRestrita"
                          >
                        </div>
                        <div
                          v-if="
                            $route.params.id_plataforma ==
                              fast_curso.id_plataforma_origem
                          "
                          class="col-sm-12 col-md-12 col-lg-8 pb-2 text-right"
                        >
                          <button
                            class="btn btn-sm btn-primary mt-2"
                            @click="
                              showModal('modalEscolherPlataformaRestrita')
                            "
                          >
                            <small>+ Adicionar plataforma</small>
                          </button>
                        </div>
                        <div
                          class="col-sm-12 col-md-12 col-lg-12 table-responsive"
                        >
                          <table class="table table-striped table-bordered">
                            <thead>
                              <tr class="bg-dark text-white">
                                <th
                                  class="text-center"
                                  colspan="3"
                                >
                                  Plataformas com permissão
                                </th>
                              </tr>
                              <tr>
                                <th scope="col">
                                  Nome da plataforma
                                </th>
                                <th
                                  scope="col"
                                  class="text-center"
                                >
                                  Status
                                </th>
                                <th
                                  class="text-center"
                                  scope="col"
                                >
                                  Ações
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="p in pageOfPlataformasRestritas"
                                :key="p.id_plataforma"
                              >
                                <td class="align-middle">
                                  {{ p.nome_plataforma }}
                                </td>
                                <td class="align-middle text-center">
                                  <span class="text-success">{{
                                    p.status == "A" ? "Ativada" : ""
                                  }}</span>
                                  <span class="text-warning">{{
                                    p.status == "T" ? "Teste" : ""
                                  }}</span>
                                </td>
                                <td class="align-middle text-center">
                                  <button
                                    v-if="
                                      $route.params.id_plataforma ==
                                        fast_curso.id_plataforma_origem
                                    "
                                    class="btn btn-sm btn-danger btn-blocke"
                                    title="Remover"
                                    @click="
                                      excluiPlataformaRestrita(p.id_plataforma)
                                    "
                                  >
                                    <small>
                                      <b-icon-person-dash-fill /> Remover
                                    </small>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-12 text-center mt-4">
                          <Pagination
                            :page-size="10"
                            :items="plataformasRestritasFiltro"
                            @changePage="pageOfPlataformasRestritas = $event"
                          />
                        </div>
                        <div class="col-12">
                          <div class="border border-secondary mt-4 mb-4" />
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4 pb-2">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Filtrar por nome"
                            @keyup="filtraUsuarioRestrito"
                          >
                        </div>
                        <div
                          v-if="
                            $route.params.id_plataforma ==
                              fast_curso.id_plataforma_origem
                          "
                          class="col-sm-12 col-md-12 col-lg-8 pb-2 text-right"
                        >
                          <button
                            class="btn btn-sm btn-primary mt-2"
                            @click="showModal('modalEscolherUsuarioRestrito')"
                          >
                            <small>+ Adicionar usuário</small>
                          </button>
                        </div>
                        <div
                          class="col-sm-12 col-md-12 col-lg-12 table-responsive"
                        >
                          <table class="table table-striped table-bordered">
                            <thead>
                              <tr class="bg-dark text-white">
                                <th
                                  class="text-center"
                                  colspan="4"
                                >
                                  Usuários com permissão
                                </th>
                              </tr>
                              <tr>
                                <th scope="col">
                                  Nome do usuário
                                </th>
                                <th
                                  scope="col"
                                  class="text-center"
                                >
                                  Email
                                </th>
                                <th
                                  scope="col"
                                  class="text-center"
                                >
                                  Ativo
                                </th>
                                <th
                                  class="text-center"
                                  scope="col"
                                >
                                  Ações
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="u in pageOfUsuariosRestritos"
                                :key="u.id_usuario"
                              >
                                <td class="align-middle">
                                  {{ u.nome_usuario }}
                                </td>
                                <td class="align-middle">
                                  {{ u.email }}
                                </td>
                                <td class="align-middle text-center">
                                  <span class="text-success">{{
                                    u.ativo == "S" ? "Ativo" : ""
                                  }}</span>
                                  <span class="text-warning">{{
                                    u.ativo != "S" ? "Não ativo" : ""
                                  }}</span>
                                </td>
                                <td class="align-middle text-center">
                                  <button
                                    v-if="
                                      $route.params.id_plataforma ==
                                        fast_curso.id_plataforma_origem
                                    "
                                    class="btn btn-sm btn-danger btn-blocke"
                                    title="Remover"
                                    @click="excluiUsuarioRestrito(u.id_usuario)"
                                  >
                                    <small>
                                      <b-icon-person-dash-fill /> Remover
                                    </small>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-12 text-center mt-4">
                          <Pagination
                            :page-size="10"
                            :items="usuariosRestritosFiltro"
                            @changePage="pageOfUsuariosRestritos = $event"
                          />
                        </div>
                      </div>
                    </tab>
                    <tab
                      v-if="
                        $route.params.id_plataforma ==
                          fast_curso.id_plataforma_origem
                      "
                      name="Permissões"
                    >
                      <h4 class="mb-4">
                        Permissões
                      </h4>
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-12 col-lg-4 pb-2 text-center"
                        >
                          <label>Plataformas podem adicionar aulas</label>
                          <div class="fd-app-meus-cursos mt-0 ml-1 pt-2">
                            <div class="fd-app-curso-aulas-list mt-0">
                              <div
                                class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                              >
                                <label class="switch">
                                  <input
                                    v-if="fast_curso.filhas_adicionam_aula"
                                    v-model="fast_curso.filhas_adicionam_aula"
                                    checked="checked"
                                    type="checkbox"
                                    @change="atualizaPermissoesCurso()"
                                  >
                                  <input
                                    v-else
                                    v-model="fast_curso.filhas_adicionam_aula"
                                    type="checkbox"
                                    @change="atualizaPermissoesCurso()"
                                  >
                                  <span class="slider round" />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-sm-12 col-md-12 col-lg-4 pb-2 text-center"
                        >
                          <label>Plataformas podem alterar aulas</label>
                          <div class="fd-app-meus-cursos mt-0 ml-1 pt-2">
                            <div class="fd-app-curso-aulas-list mt-0">
                              <div
                                class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                              >
                                <label class="switch">
                                  <input
                                    v-if="fast_curso.filhas_alteram_aula"
                                    v-model="fast_curso.filhas_alteram_aula"
                                    checked="checked"
                                    type="checkbox"
                                    @change="atualizaPermissoesCurso()"
                                  >
                                  <input
                                    v-else
                                    v-model="fast_curso.filhas_alteram_aula"
                                    type="checkbox"
                                    @change="atualizaPermissoesCurso()"
                                  >
                                  <span class="slider round" />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </tab>
                    <tab
                      v-if="fast_curso.gera_certificado"
                      name="Certificado"
                      :is-disabled="true"
                    >
                      <h4 class="mb-4">
                        Certificado
                      </h4>
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6 pb-2">
                          <div class="row">
                            <div class="col-12">
                              <h6>Forma de obtenção do certificado:</h6>
                            </div>
                            <div class="col-12">
                              <select
                                v-model="formaObterCertificado"
                                class="form-control"
                                @change="verificaFormaCertificado()"
                              >
                                <option value="C">
                                  Completando curso
                                </option>
                                <option value="A">
                                  Atingindo nota em avaliação
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="formaObterCertificado == 'A'"
                          class="col-sm-12 col-md-12 col-lg-6 pb-2"
                        >
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-6">
                              <h6>Nota máxima da UC:</h6>
                              <input
                                v-model="fast_curso.nota_maxima"
                                class="form-control text-center"
                                type="number"
                                min="0"
                                @change="alterarNotasCurso()"
                              >
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6">
                              <h6>Nota média da UC:</h6>
                              <input
                                v-model="fast_curso.nota_media"
                                class="form-control text-center"
                                type="number"
                                min="0"
                                @change="alterarNotasCurso()"
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 pb-2 mt-4">
                          <div class="row">
                            <div
                              v-if="fastPlataformaCertificados.length"
                              class="col-12"
                            >
                              <button
                                class="btn btn-primary"
                                @click="getFastCursoCertificados"
                              >
                                <small>Ver certificados</small>
                              </button>
                            </div>
                            <div
                              v-if="fastPlataformaCertificados.length"
                              class="col-12"
                            >
                              <div class="row pl-4 pr-4">
                                <carousel
                                  :navigate-to="fastCertificadoEscolhido"
                                  :navigation-enabled="true"
                                  :per-page="1"
                                  :mouse-drag="false"
                                  class="col-12"
                                >
                                  <slide
                                    v-for="c in fastPlataformaCertificados"
                                    :key="c.id_certificado"
                                  >
                                    <div class="text-right mb-2">
                                      <button
                                        v-if="
                                          fastCursoCertificados.id_certificado !=
                                            c.id_certificado
                                        "
                                        class="btn btn-sm btn-success"
                                        @click="insereFastCursoCertificado(c)"
                                      >
                                        <small>Selecionar este certificado</small>
                                      </button>
                                      <button
                                        v-else
                                        class="btn btn-sm btn-danger"
                                        @click="excluiFastCursoCertificado(c)"
                                      >
                                        <small>Excluir</small>
                                      </button>
                                    </div>
                                    <div
                                      class="card"
                                      v-html="c.html_template"
                                    />
                                  </slide>
                                </carousel>
                              </div>
                            </div>
                            <div
                              v-else
                              class="col-12 text-center"
                            >
                              <h6>Nenhum certificado na plataforma</h6>
                              <a
                                :href="
                                  '/plataforma/' +
                                    $route.params.id_plataforma +
                                    '/admin/personalizar-certificados'
                                "
                                class="btn btn-sm btn-primary"
                              >Criar certificados</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </tab>
                    <tab
                      v-if="fast_curso.plataforma_venda == 'S'"
                      name="Financeiro"
                      :is-disabled="true"
                    >
                      <h4 class="mb-4">
                        Financeiro
                      </h4>
                      <div class="row">
                        <div
                          id="div_financeiro_curso"
                          class="col-12 mt-0 ml-0 mr-0"
                        >
                          <div class="row">
                            <div
                              class="col-sm-12 col-md-12 col-lg-12"
                              style
                            >
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                              <label>URL da loja:</label>
                              <p>
                                <a
                                  target="_blank"
                                  :href="
                                    'https://' +
                                      fastDomain +
                                      '/loja-virtual/' +
                                      $route.params.id_plataforma
                                  "
                                >
                                  <span class="text-primary">{{
                                    "https://" +
                                      fastDomain +
                                      "/loja-virtual/" +
                                      $route.params.id_plataforma
                                  }}</span>
                                </a>
                              </p>
                              <label>URL de checkout:</label>
                              <p v-if="fast_curso.url_pagamento">
                                <a
                                  target="_blank"
                                  :href="fast_curso.url_pagamento"
                                >
                                  <span class="text-primary">{{
                                    fast_curso.url_pagamento
                                  }}</span>
                                </a>
                              </p>
                              <small
                                v-else
                                class="text-info"
                              >Link não disponível</small>
                              <div>
                                <div class="col-12 text-center mt-4">
                                  <span
                                    class="text-danger txt-campos-obrigatorios"
                                    style="display: none"
                                  >Preencha os campos obrigatórios</span>
                                </div>
                                <div class="col-12 text-center mt-4">
                                  <button
                                    class="btn btn-primary"
                                    type="button"
                                    @click="criaProdutoVindi()"
                                  >
                                    Salvar alterações
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </tab>
                    <tab
                      name="Clone"
                    >
                      <h4 class="mb-4">
                        Clonar unidade
                      </h4>
                      <div class="row col-12">
                        <label class="col-6 p-0">
                          Nome da nova unidade:
                          <input type="text" class="form-control" v-model="fastCursoNomeClone">
                        </label>
                        <div class="col-6 d-flex justify-content-end align-items-center">
                          <div>
                            <button v-if="!fastCursoNomeClone" class="btn btn-primary" @click="exibeModalClonarUnidade" disabled>Clonar unidade</button>
                            <button v-else class="btn btn-primary" @click="exibeModalClonarUnidade">Clonar unidade</button>
                          </div>
                        </div>
                      </div>
                    </tab>
                  </tabs>
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' +
                      $route.params.id_plataforma +
                      '/secretaria/unidades-curriculares'
                  "
                  @click.prevent="$router.push('/plataforma/' +
                    $route.params.id_plataforma +
                    '/secretaria/unidades-curriculares')"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalEscolherDisciplina"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Escolha uma disciplina</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEscolherDisciplina')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div>
                <vue-tree-list
                  :model="treeDisciplinas"
                  default-tree-node-name="Disciplina"
                  :default-expanded="false"
                  @delete-node="confirmaDisciplinaEscolhida"
                >
                  <template v-slot:leafNameDisplay="slotProps">
                    <span>{{ slotProps.model.name }}</span>
                  </template>
                  <span
                    slot="delNodeIcon"
                    title="Escolher essa disciplina"
                    style="cursor: pointer"
                    class="icon"
                  >
                    <button class="btn btn-sm btn-success pt-0 pb-0">
                      <small>+ Adicionar</small>
                    </button>
                  </span>
                  <span
                    slot="addTreeNodeIcon"
                    title="Escolher essa disciplina"
                    class="icon d-none"
                  />
                  <span
                    slot="addLeafNodeIcon"
                    class="icon d-none"
                  />
                  <span
                    slot="editNodeIcon"
                    title="Editar"
                    class="icon d-none"
                  />
                  <span
                    slot="treeNodeIcon"
                    class="icon"
                  >📚</span>
                </vue-tree-list>

                <div
                  v-if="!treeDisciplinas.children"
                  class="text-center"
                >
                  <h6>Nenhuma disciplina cadastrada</h6>
                  <a
                    :href="
                      '/plataforma/' +
                        $route.params.id_plataforma +
                        '/admin/personalizar-plataforma#disciplinas'
                    "
                    class="btn btn-sm btn-primary"
                  >Cadastrar disciplinas</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalEscolherSegmento"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Escolha um segmento</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEscolherSegmento')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div>
                <vue-tree-list
                  :model="treeSegmentos"
                  default-tree-node-name="Disciplina"
                  :default-expanded="false"
                  @delete-node="confirmaSegmentoEscolhido"
                >
                  <template v-slot:leafNameDisplay="slotProps">
                    <span>{{ slotProps.model.name }}</span>
                  </template>
                  <span
                    slot="delNodeIcon"
                    title="Escolher esse segmento"
                    style="cursor: pointer"
                    class="icon"
                  >
                    <button class="btn btn-sm btn-success pt-0 pb-0">
                      <small>+ Adicionar</small>
                    </button>
                  </span>
                  <span
                    slot="addTreeNodeIcon"
                    title="Escolher essae segmento"
                    class="icon d-none"
                  />
                  <span
                    slot="addLeafNodeIcon"
                    class="icon d-none"
                  />
                  <span
                    slot="editNodeIcon"
                    title="Editar"
                    class="icon d-none"
                  />
                  <span
                    slot="treeNodeIcon"
                    class="icon"
                  >📚</span>
                </vue-tree-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalEscolherTag"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Insira uma tag</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEscolherTag')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <input
                v-model="fastNovaTag"
                type="text"
                class="form-control"
                placeholder="Insira sua tag aqui"
              >
            </div>
            <div class="col-12 mt-4 text-center">
              <button
                class="btn btn-primary"
                @click="confirmaTagEscolhida()"
              >
                Salvar alterações
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalEscolherProfessor"
      :shift-y="0.1"
      height="auto"
      :width="modalWidth"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4">
          <h4>Buscar professor</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEscolherProfessor')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 pb-2">
              <input
                type="text"
                class="form-control"
                placeholder="Filtrar por nome"
                @keyup="filtraProfessor"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 table-responsive">
              <table class="table table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" />
                    <th scope="col">
                      Nome
                    </th>
                    <th scope="col">
                      Email
                    </th>
                    <th
                      class="text-center"
                      scope="col"
                    >
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody v-if="pageOfProfessores.length">
                  <tr
                    v-for="prof in pageOfProfessores"
                    :key="prof.id_professor"
                  >
                    <td scope="row">
                      <div
                        :style="{
                          backgroundImage:
                            'url(' + ajustaLinkImageUser(prof.image) + ')',
                          width: '60px',
                          height: '60px',
                          margin: 'auto',
                          marginBottom: '5px',
                          borderRadius: '50%',
                          backgroundSize: '100%',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: '50%',
                        }"
                      />
                    </td>
                    <td class="align-middle">
                      {{ prof.first_name + " " + prof.last_name }}
                    </td>
                    <td class="align-middle">
                      {{ prof.email }}
                    </td>
                    <td class="align-middle text-center">
                      <button
                        class="btn btn-sm btn-success btn-block"
                        title="Adicionar como professor desta aula"
                        @click="insereProfessorCurso(prof.id_professor, 0)"
                      >
                        <small>
                          <b-icon-person-plus-fill /> Professor
                        </small>
                      </button>
                      <button
                        class="btn btn-sm btn-success btn-block"
                        title="Adicionar como professor desta aula"
                        @click="insereProfessorCurso(prof.id_professor, 1)"
                      >
                        <small> <b-icon-person-plus-fill /> Tutor </small>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      class="text-center"
                      colspan="4"
                    >
                      Nenhum professor da plataforma disponível
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12 text-center mt-4">
              <Pagination
                :page-size="5"
                :items="professoresFiltro"
                @changePage="pageOfProfessores = $event"
              />
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalEscolherPlataformaRestrita"
      :shift-y="0.1"
      height="auto"
      :width="modalWidth"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4">
          <h4>Buscar plataformas</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEscolherPlataformaRestrita')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 pb-2">
              <input
                type="text"
                class="form-control"
                placeholder="Filtrar por nome"
                @keyup="filtraPlataforma"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 table-responsive">
              <table class="table table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">
                      Nome da plataforma
                    </th>
                    <th scope="col">
                      Status
                    </th>
                    <th
                      class="text-center"
                      scope="col"
                    >
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody v-if="pageOfPlataformas.length">
                  <tr
                    v-for="p in pageOfPlataformas"
                    :key="p.id_plataforma"
                  >
                    <td>{{ p.nome_plataforma }}</td>
                    <td>{{ p.status }}</td>
                    <td>
                      <button
                        class="btn btn-sm btn-success btn-block"
                        title="Adicionar como professor desta aula"
                        @click="inserePlataformaRestrita(p.id_plataforma)"
                      >
                        <small>
                          <b-icon-person-plus-fill /> Adicionar
                        </small>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      class="text-center"
                      colspan="4"
                    >
                      Nenhuma plataforma disponível
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12 text-center mt-4">
              <Pagination
                :page-size="5"
                :items="plataformasFiltro"
                @changePage="pageOfPlataformas = $event"
              />
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalEscolherUsuarioRestrito"
      :shift-y="0.1"
      height="auto"
      :width="modalWidth"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4">
          <h4>Buscar usuários</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEscolherUsuarioRestrito')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 pb-2">
              <input
                type="text"
                class="form-control"
                placeholder="Filtrar por nome"
                @keyup="filtraUsuario"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 table-responsive">
              <table class="table table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">
                      Nome do usuário
                    </th>
                    <th scope="col">
                      Email
                    </th>
                    <th
                      scope="col"
                      class="text-center"
                    >
                      Ativo
                    </th>
                    <th
                      class="text-center"
                      scope="col"
                    >
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody v-if="pageOfUsuarios.length">
                  <tr
                    v-for="u in pageOfUsuarios"
                    :key="u.email"
                  >
                    <td>{{ u.nome_usuario }}</td>
                    <td>{{ u.email }}</td>
                    <td class="text-center">
                      <span class="text-success">{{
                        u.ativo == "S" ? "Ativo" : ""
                      }}</span>
                      <span class="text-warning">{{
                        u.ativo != "S" ? "Não ativo" : ""
                      }}</span>
                    </td>
                    <td class="text-center">
                      <button
                        v-if="u.ativo == 'S'"
                        class="btn btn-sm btn-success btn-block"
                        title="Adicionar como professor desta aula"
                        @click="insereUsuarioRestrito(u.id_usuario)"
                      >
                        <small>
                          <b-icon-person-plus-fill /> Adicionar
                        </small>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      class="text-center"
                      colspan="4"
                    >
                      Nenhum usuário disponível
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12 text-center mt-4">
              <Pagination
                :page-size="5"
                :items="usuariosFiltro"
                @changePage="pageOfUsuarios = $event"
              />
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalExcluirFastCurso"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4">
          <h4>Excluir UC?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirFastCurso')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 pb-2">
              <button
                class="btn btn-sm btn-danger"
                @click="excluirFastCurso()"
              >
                Confirmar
              </button>
              <button
                class="btn btn-sm btn-secondary ml-2"
                @click="hideModal('modalExcluirFastCurso')"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalClonarUnidade"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4">
          <h4>Confirmar ação?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalClonarUnidade')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 pb-2">
              <p>
                Deseja realmente clonar a unidade {{ fast_curso.nome_curso }} com o nome de <strong>{{ fastCursoNomeClone }}</strong>?
              </p>
              <button
                class="btn btn-sm btn-primary"
                @click="clonarCurso()"
              >
                Confirmar
              </button>
              <button
                class="btn btn-sm btn-secondary ml-2"
                @click="hideModal('modalClonarUnidade')"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <!-- Notificações -->
    <notifications
      group="foo"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
//import ImageSiteFormEffect01 from "@/assets/images/form-effect-01.png";
import Pagination from "../components/Pagination";
import { VueTreeList, Tree, TreeNode } from "vue-tree-list"; //https://github.com/ParadeTo/vue-tree-list#readme
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

import { Carousel, Slide } from "vue-carousel"; //https://github.com/ssense/vue-carousel#readme

export default {
  name: "HomeInternoSecretariaUnidadeCurricular",
  components: {
    Pagination,
    VueTreeList,
    VueCropper,
    Carousel,
    Slide,
  },
  mixins: [methods],
  data: function () {
    return {
      //Dominio atual
      fastDomain: settings.fastDomain,
      //ImageSiteFormEffect01,
      nome_plataforma: "",
      banner_plataforma: "",
      logo_plataforma: "",
      fast_curso: {
        acesso: "R",
        acesso_principal: "",
        admin_fast: "",
        carga_horaria: "",
        data_disponivel: "",
        data_expiracao: "",
        detalhe_curso: "",
        gera_certificado: false,
        id_curso: 0,
        id_plataforma: 0,
        id_plataforma_origem: "",
        id_produto_vindi: "",
        id_produto_vindi_principal: "",
        id_requisicao: "",
        id_usuario_responsavel: "",
        libera_automatica: true,
        libera_automatica_principal: true,
        logo_curso: "",
        multidisciplinar: "",
        nome_curso: "",
        nota_maxima: "",
        nota_media: "",
        origem: "OC",
        plataforma_venda: "",
        publicado: "",
        publicado_principal: "",
        quantidade: "",
        reiniciar: "",
        url_pagamento: "",
        logo_curso_loja: "",
        html_apresentacao: "",
        id_item_cobranca: "",
        filhas_adicionam_aula: false,
        filhas_alteram_aula: false,
        disciplinas: [],
        segmentos: [],
        tags: [],
      },
      imgSrc: "",
      // Lista de professores
      professoresTotal: [],
      professoresFiltro: [],
      pageOfProfessores: [],
      // Lista de professores do curso
      professoresCursoTotal: [],
      professoresCursoFiltro: [],
      pageOfProfessoresCurso: [],
      // Lista de plataformas restritas
      plataformasTotal: [],
      plataformasFiltro: [],
      pageOfPlataformas: [],
      plataformasRestritasTotal: [],
      plataformasRestritasFiltro: [],
      pageOfPlataformasRestritas: [],
      // Lista de usuarios restritas
      usuariosTotal: [],
      usuariosFiltro: [],
      pageOfUsuarios: [],
      usuariosRestritosTotal: [],
      usuariosRestritosFiltro: [],
      pageOfUsuariosRestritos: [],
      // Disciplinas
      treeDisciplinas: new Tree({
        name: "Primeira disciplina",
        id: 0,
        isLeaf: false,
        addLeafNodeDisabled: true,
      }),
      // Segmentos
      treeSegmentos: new Tree({
        name: "Primeiro segmento",
        id: 0,
        isLeaf: false,
        addLeafNodeDisabled: true,
      }),
      // Tags
      fastNovaTag: "",
      // VINDI
      fastProdutoVindi: {
        code: "",
        description: "",
        id: "",
        name: "",
        pricing_schema: {
          id: "",
          price: "",
          short_format: "",
        },
        status: "",
      },
      // Certificado
      formaObterCertificado: "C",
      fastPlataformaCertificados: [
        {
          id_certificado: 0,
          html_template: "",
        },
      ],
      fastCursoCertificados: [],
      fastCertificadoEscolhido: 0,
      modalWidth: "80%",
      fastCursoNomeClone: "",
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma && this.$route.params.id_curso) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'administrador')
        .then(() => {          
            this.getFastCurso();
            this.getPlataformasRestritas();
            this.getUsuariosRestritos();
            this.getFastDisciplinas();
            this.getFastSegmentos();         
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    async getFastCurso() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso_plataforma/lista_curso_admin?id_plataforma=" +
            this.$route.params.id_plataforma +
            "&id_curso=" +
            this.$route.params.id_curso,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let objCurso = Array.from(json);
        if (objCurso.length > 0) {
          objCurso[0].data_disponivel
            ? (objCurso[0].data_disponivel = objCurso[0].data_disponivel.split(
                "T"
              )[0])
            : "";
          objCurso[0].data_expiracao
            ? (objCurso[0].data_expiracao = objCurso[0].data_expiracao.split(
                "T"
              )[0])
            : "";

          objCurso[0].libera_automatica == "S"
            ? (objCurso[0].libera_automatica = true)
            : (objCurso[0].libera_automatica = false);
          objCurso[0].gera_certificado == "S"
            ? (objCurso[0].gera_certificado = true)
            : (objCurso[0].gera_certificado = false);

          objCurso[0].disciplinas = [];
          objCurso[0].segmentos = [];
          objCurso[0].tags = [];

          objCurso[0].nome_curso = objCurso[0].nome_curso ? objCurso[0].nome_curso : objCurso[0].nome_curso_mae
          objCurso[0].detalhe_curso = objCurso[0].detalhe_curso ? objCurso[0].detalhe_curso : objCurso[0].detalhe_curso_mae
          objCurso[0].carga_horaria = objCurso[0].carga_horaria ? objCurso[0].carga_horaria : objCurso[0].carga_horaria_mae
          objCurso[0].logo_curso = objCurso[0].logo_curso ? objCurso[0].logo_curso : objCurso[0].logo_curso_mae

          this.fast_curso = objCurso[0];
          this.getFastDisciplinasCurso();
          this.getFastSegmentosCurso();
          this.getFastTagsCurso();
          this.getProfessoresCurso();
          //this.getFastCursoCertificados();
          
          //if (this.fast_curso.id_produto_vindi) this.buscaProdutoVindi(this.fast_curso.id_produto_vindi);

          if (this.fast_curso.nota_maxima || this.fast_curso.nota_media)
            this.formaObterCertificado = "A";

          this.$store.state.fastCarregando = false;
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    // Professores
    async getProfessoresPlataforma() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_professor/lista?id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let objProfessores = Array.from(json);
        if (objProfessores.length > 0) {
          objProfessores.forEach((e, index) => {
            this.professoresCursoTotal.forEach((p, index2) => {
              if (e.id_professor == p.id_professor) {
                objProfessores.splice(index, 1);
              }
            });
          });
          this.professoresTotal = objProfessores;
          this.professoresFiltro = objProfessores;
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    filtraProfessor(e) {
      let text = e.target.value;
      this.professoresFiltro = this.professoresTotal.filter(
        (e) =>
          e.first_name.toLowerCase().indexOf(text.toLowerCase()) != "-1" ||
          e.last_name.toLowerCase().indexOf(text.toLowerCase()) != "-1"
      );
    },
    filtraProfessorCurso(e) {
      let text = e.target.value;
      this.professoresCursoFiltro = this.professoresCursoTotal.filter(
        (e) =>
          e.first_name.toLowerCase().indexOf(text.toLowerCase()) != "-1" ||
          e.last_name.toLowerCase().indexOf(text.toLowerCase()) != "-1"
      );
    },
    async getProfessoresCurso() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso_professor/lista?id_plataforma=" +
            this.$route.params.id_plataforma +
            "&id_curso=" +
            this.$route.params.id_curso,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
          this.professoresCursoTotal = obj;
          this.professoresCursoFiltro = obj;
        } else {
          this.professoresCursoTotal = [];
          this.professoresCursoFiltro = [];
        }
        this.getProfessoresPlataforma();
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async insereProfessorCurso(id_professor, tutor) {
      let fast_curso_professor = {
        id_curso: this.fast_curso.id_curso,
        id_professor: id_professor,
        tutor: tutor ? true : false,
        id_plataforma: this.$route.params.id_plataforma,
      };
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_curso_professor/insere",
          this.fastAjaxOptions("POST", JSON.stringify(fast_curso_professor))
        );
        let json = await resp.json();
        this.getProfessoresCurso();
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Professor inserido com sucesso",
        });
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async excluiProfessorCurso(id_professor) {
      let fast_curso_professor = {
        id_curso: this.fast_curso.id_curso,
        id_professor: id_professor,
        tutor: 0,
        id_plataforma: this.$route.params.id_plataforma,
      };
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_curso_professor/exclui",
          this.fastAjaxOptions("POST", JSON.stringify(fast_curso_professor))
        );
        let json = await resp.json();
        this.getProfessoresCurso();
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Professor excluído com sucesso",
        });
      } catch (e) {
        console.log("Erro", e);
      }
    },
    // Restrito plataformas
    async getPlataformasFilhas() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma/lista_filhas_curso_restrito?id_plataforma=" +
            this.$route.params.id_plataforma +
            "&id_curso=" +
            this.$route.params.id_curso,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
          let plataformasDisponiveis = obj.filter(
            ({ id_usuario: id1 }) =>
              !this.plataformasRestritasTotal.some(
                ({ id_plataforma: id2 }) => id2 === id1
              )
          );
          this.plataformasTotal = plataformasDisponiveis;
          this.plataformasFiltro = plataformasDisponiveis;
        } else {
          this.plataformasTotal = [];
          this.plataformasFiltro = [];
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getPlataformasRestritas() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso_restrito/lista_plataformas_restritas?id_curso=" +
            this.$route.params.id_curso +
            "&id_plataforma=0",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
          this.plataformasRestritasTotal = obj;
          this.plataformasRestritasFiltro = obj;
        } else {
          this.plataformasRestritasTotal = [];
          this.plataformasRestritasFiltro = [];
        }
        this.getPlataformasFilhas();
      } catch (e) {
        console.log("Erro", e);
      }
    },
    filtraPlataforma(e) {
      let text = e.target.value;
      this.plataformasFiltro = this.plataformasTotal.filter(
        (e) =>
          e.nome_plataforma.toLowerCase().indexOf(text.toLowerCase()) != "-1"
      );
    },
    filtraPlataformaRestrita(e) {
      let text = e.target.value;
      this.plataformasRestritasFiltro = this.plataformasRestritasTotal.filter(
        (e) =>
          e.nome_plataforma.toLowerCase().indexOf(text.toLowerCase()) != "-1"
      );
    },
    async inserePlataformaRestrita(id_plataforma) {
      let fast_curso_plataforma = {
        id_curso: this.fast_curso.id_curso,
        id_plataforma: id_plataforma,
      };
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso_restrito/insere_plataforma_restrita",
          this.fastAjaxOptions("POST", JSON.stringify(fast_curso_plataforma))
        );
        let json = await resp.json();
        this.getPlataformasRestritas();
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Plataformas inserida com sucesso",
        });
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async excluiPlataformaRestrita(id_plataforma) {
      let fast_curso_plataforma = {
        id_curso: this.fast_curso.id_curso,
        id_plataforma: id_plataforma,
      };
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso_restrito/exclui_plataforma_restrita",
          this.fastAjaxOptions("POST", JSON.stringify(fast_curso_plataforma))
        );
        let json = await resp.json();
        this.getPlataformasRestritas();
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Plataforma excluída com sucesso",
        });
      } catch (e) {
        console.log("Erro", e);
      }
    },
    // Restrito usuários
    async getPlataformasUsuarios() {
      let param = `id_plataforma=${this.$route.params.id_plataforma}&administrador=&professor=&aluno=&tesouraria=&secretario=&responsavel=`
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_usuario/lista_usuarios_plataforma?" + param,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        console.log("getPlataformasUsuarios", obj);
        if (obj.length > 0) {
          let usuariosDisponiveis = obj.filter(
            ({ id_usuario: id1 }) =>
              !this.usuariosRestritosTotal.some(
                ({ id_usuario: id2 }) => id2 === id1
              )
          );
          usuariosDisponiveis.forEach((e) => {
            if (!e.nome_usuario) e.nome_usuario = "Não cadastrado";
          });

          this.usuariosTotal = usuariosDisponiveis;
          this.usuariosFiltro = usuariosDisponiveis;
        } else {
          this.usuariosTotal = [];
          this.usuariosFiltro = [];
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getUsuariosRestritos() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso_restrito/lista_usuarios_restritos?id_curso=" +
            this.$route.params.id_curso +
            "&id_plataforma=" +
            this.$route.params.id_plataforma +
            "&id_usuario=0",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        console.log(json);
        let obj = Array.from(json);
        if (obj.length > 0) {
          this.usuariosRestritosTotal = obj;
          this.usuariosRestritosFiltro = obj;
        } else {
          this.usuariosRestritosTotal = [];
          this.usuariosRestritosFiltro = [];
        }
        this.getPlataformasUsuarios();
      } catch (e) {
        console.log("Erro", e);
      }
    },
    filtraUsuario(e) {
      let text = e.target.value;
      this.usuariosFiltro = this.usuariosTotal.filter(
        (e) => e.nome_usuario.toLowerCase().indexOf(text.toLowerCase()) != "-1"
      );
    },
    filtraUsuarioRestrito(e) {
      let text = e.target.value;
      this.usuariosRestritosFiltro = this.usuariosRestritosTotal.filter(
        (e) => e.nome_usuario.toLowerCase().indexOf(text.toLowerCase()) != "-1"
      );
    },
    async insereUsuarioRestrito(id_usuario) {
      let fast_curso_plataforma = {
        id_curso: this.fast_curso.id_curso,
        id_usuario_responsavel: id_usuario,
        id_plataforma: this.$route.params.id_plataforma,
      };
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso_restrito/insere_usuario_restrito",
          this.fastAjaxOptions("POST", JSON.stringify(fast_curso_plataforma))
        );
        let json = await resp.json();
        this.getUsuariosRestritos();
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Usuário inserido com sucesso",
        });
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async excluiUsuarioRestrito(id_usuario) {
      let fast_curso_plataforma = {
        id_curso: this.fast_curso.id_curso,
        id_usuario_responsavel: id_usuario,
        id_plataforma: this.$route.params.id_plataforma,
      };
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso_restrito/exclui_usuario_restrito",
          this.fastAjaxOptions("POST", JSON.stringify(fast_curso_plataforma))
        );
        let json = await resp.json();
        this.getUsuariosRestritos();
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Usuário excluído com sucesso",
        });
      } catch (e) {
        console.log("Erro", e);
      }
    },
    // Geral
    async alterarFastCurso() {
      let libera_automatica = "";
      let gera_certificado = "";
      this.fast_curso.libera_automatica
        ? (libera_automatica = "S")
        : (libera_automatica = "N");
      this.fast_curso.gera_certificado
        ? (gera_certificado = "S")
        : (gera_certificado = "N");
      let model = {
        fast_curso: {
          id_curso: this.fast_curso.id_curso,
          nome_curso: this.fast_curso.nome_curso,
          detalhe_curso: this.fast_curso.detalhe_curso,
          publicado: this.fast_curso.publicado ? true : false,
          carga_horaria: this.fast_curso.carga_horaria,
          origem: this.fast_curso.origem,
          acesso: this.fast_curso.acesso,
          logo_curso: this.fast_curso.logo_curso,
          multidisciplinar: this.fast_curso.multidisciplinar,
          libera_automatica: 'S',
        },
        fast_curso_plataforma: {
          gera_certificado: 'N',
          id_plataforma: this.fast_curso.id_plataforma,
          id_requisicao: this.fast_curso.id_requisicao,
          data_disponivel: '1979-01-01',
          data_expiracao: '9999-01-01',
          id_usuario_responsavel: this.fast_curso.id_usuario_responsavel,
          quantidade: this.fast_curso.quantidade,
          reiniciar: this.fast_curso.reiniciar,
        },
      };
      this.$store.state.fastCarregando = true;
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_curso/atualiza",
          this.fastAjaxOptions("POST", JSON.stringify(model))
        );
        let json = await resp.json();
        this.$store.state.fastCarregando = false;
        this.getFastCurso();
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "UC atualizada com sucesso",
        });
      } catch (e) {
        this.$store.state.fastCarregando = false;
        console.log("Erro", e);
      }
    },
    async excluirFastCurso() {
      const fast_curso_plataforma = {
        id_plataforma: this.$route.params.id_plataforma,
        id_curso: this.$route.params.id_curso,
      };
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_curso/exclui",
          this.fastAjaxOptions("POST", JSON.stringify(fast_curso_plataforma))
        );
        let json = await resp.json();

        window.location.href =
          "/Plataforma/" +
          this.$route.params.id_plataforma +
          "/secretaria/unidades-curriculares";
      } catch (e) {
        console.log("Erro", e);
        this.$notify({
          group: "foo",
          type: "error",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Erro ao excluir UC",
        });
      }
    },
    // Disciplinas
    async getFastDisciplinasCurso() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso_disciplina_plataforma/lista?id_curso=" +
            this.$route.params.id_curso,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);

        if (obj.length) {
          this.fast_curso.disciplinas = obj;
        } else {
          this.fast_curso.disciplinas = [];
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getFastDisciplinas() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_disciplinas/lista?id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          obj.forEach((e) => {
            e.name = e.descricao;
            e.id = e.id_plataforma_area_disciplina;
            e.isLeaf = false;
            e.addLeafNodeDisabled = true;
          });
          let arrayDisciplinasPai = obj.filter((d) => {
            if (d.id_plataforma_area_disciplina_pai == "0") return d;
          });
          arrayDisciplinasPai.forEach(function (d, index) {
            verificaNivel2(d, index);
          });

          function verificaNivel2(d, index) {
            obj.forEach(function (d2) {
              if (
                d.id_plataforma_area_disciplina ==
                d2.id_plataforma_area_disciplina_pai
              ) {
                if (!arrayDisciplinasPai[index].children)
                  arrayDisciplinasPai[index].children = [];
                arrayDisciplinasPai[index].children.push(d2);

                verificaNivelUltimos(d2);
              }
            });
          }

          function verificaNivelUltimos(d) {
            obj.forEach(function (d2) {
              if (
                d.id_plataforma_area_disciplina ==
                d2.id_plataforma_area_disciplina_pai
              ) {
                if (!d.children) d.children = [];
                d.children.push(d2);
                verificaNivelUltimos(d2);
              }
            });
          }

          this.treeDisciplinas = new Tree(arrayDisciplinasPai);
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    confirmaDisciplinaEscolhida(obj) {
      let fast_curso_disciplina_plataforma = {
        id_curso: this.fast_curso.id_curso,
        id_plataforma_area_disciplina: obj.id,
      };
      fetch(
        `${settings.endApiFastEad}api/fast_curso_disciplina_plataforma/insere`,
        this.fastAjaxOptions(
          "POST",
          JSON.stringify(fast_curso_disciplina_plataforma)
        )
      )
        .then((resp) => resp.json())
        .then((obj) => {
          this.hideModal("modalEscolherDisciplina");
          // Notificação
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Disciplina salva com sucesso",
          });
          this.getFastDisciplinasCurso();
        })
        .catch((e) => {
          console.log(e);
          // Notificação
          this.$notify({
            group: "foo",
            type: "error",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Erro ao salvar disciplina",
          });
        });
    },
    async excluirDisciplinaCurso(disciplina) {
      let fast_curso_disciplina_plataforma = {
        id_curso: this.fast_curso.id_curso,
        id_plataforma_area_disciplina: disciplina.id_plataforma_area_disciplina,
      };
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso_disciplina_plataforma/exclui",
          this.fastAjaxOptions(
            "POST",
            JSON.stringify(fast_curso_disciplina_plataforma)
          )
        );
        let json = await resp.json();
        this.getFastDisciplinasCurso();
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Disciplina excluída com sucesso",
        });
      } catch (e) {
        console.log("Erro", e);
      }
    },
    // Segmentos
    async getFastSegmentosCurso() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso_segmento/lista?id_curso=" +
            this.$route.params.id_curso,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fast_curso.segmentos = obj;
        } else {
          this.fast_curso.segmentos = [];
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getFastSegmentos() {
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_segmento/lista",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        //console.log(obj);
        if (obj.length) {
          obj.forEach((e) => {
            e.name = e.nome_segmento;
            e.id = e.id_segmento;
            e.isLeaf = false;
            e.addLeafNodeDisabled = true;
          });

          this.treeSegmentos = new Tree(obj);
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    confirmaSegmentoEscolhido(obj) {
      let fast_curso_segmento = {
        id_curso: this.fast_curso.id_curso,
        id_segmento: obj.id,
      };
      fetch(
        `${settings.endApiFastEad}api/fast_curso_segmento/insere`,
        this.fastAjaxOptions("POST", JSON.stringify(fast_curso_segmento))
      )
        .then((resp) => resp.json())
        .then((obj) => {
          this.hideModal("modalEscolherSegmento");
          // Notificação
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Segmento salvo com sucesso",
          });
          this.getFastSegmentosCurso();
        })
        .catch((e) => {
          console.log(e);
          // Notificação
          this.$notify({
            group: "foo",
            type: "error",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Erro ao salvar segmento",
          });
        });
    },
    async excluirSegmentoCurso(segmento) {
      let fast_curso_segmento = {
        id_curso: this.fast_curso.id_curso,
        id_segmento: segmento.id_segmento,
      };
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_curso_segmento/exclui",
          this.fastAjaxOptions("POST", JSON.stringify(fast_curso_segmento))
        );
        let json = await resp.json();
        this.getFastSegmentosCurso();
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Segmento excluída com sucesso",
        });
      } catch (e) {
        console.log("Erro", e);
      }
    },
    // Tags
    async getFastTagsCurso() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso_tag/lista?id_curso=" +
            this.$route.params.id_curso,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fast_curso.tags = obj;
        } else {
          this.fast_curso.tags = [];
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    confirmaTagEscolhida() {
      if (this.fastNovaTag) {
        let fast_curso_tag = {
          id_curso: this.fast_curso.id_curso,
          nome_tag: this.fastNovaTag,
        };
        fetch(
          `${settings.endApiFastEad}api/fast_curso_tag/insere`,
          this.fastAjaxOptions("POST", JSON.stringify(fast_curso_tag))
        )
          .then((resp) => resp.json())
          .then((obj) => {
            this.hideModal("modalEscolherTag");
            this.fastNovaTag = "";
            // Notificação
            this.$notify({
              group: "foo",
              type: "success",
              duration: 5000,
              speed: 1000,
              title: "Status",
              text: "Tag salva com sucesso",
            });
            this.getFastTagsCurso();
          })
          .catch((e) => {
            console.log(e);
            // Notificação
            this.$notify({
              group: "foo",
              type: "error",
              duration: 5000,
              speed: 1000,
              title: "Status",
              text: "Erro ao salvar tag",
            });
          });
      } else {
        this.$notify({
          group: "foo",
          type: "error",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Digite uma tag",
        });
      }
    },
    async excluirTagCurso(tag) {
      let fast_curso_tag = {
        id_curso: this.fast_curso.id_curso,
        nome_tag: tag.nome_tag,
      };
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_curso_tag/exclui",
          this.fastAjaxOptions("POST", JSON.stringify(fast_curso_tag))
        );
        let json = await resp.json();
        this.getFastTagsCurso();
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Tag excluída com sucesso",
        });
      } catch (e) {
        console.log("Erro", e);
      }
    },
    // Image cropper
    setImageLogoCurso(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (file.size / 1024 > 1024) {
        alert("Não utilize imagens acima de 1MB");
        return;
      }

      this.showModal("modalImageCropLogoCurso");

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.logo_plataforma = event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    cropImage(tipo) {
      if (tipo == "LogoCurso") {
        this.fast_curso.logo_curso = this.$refs.cropper
          .getCroppedCanvas()
          .toDataURL();
        this.alterarFastCurso();
        this.hideModal("modalImageCropLogoCurso");
      }
    },
    // Financeiro
    async buscaProdutoVindi(id_produto_vindi) {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso/lista_produto_vindi?id_produto_vindi=" +
            id_produto_vindi,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();

        let obj = JSON.parse(json);
        if (obj.product) {
          this.fastProdutoVindi = obj.product;
        }
        //console.log(this.fastProdutoVindi);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async criaProdutoVindi() {
      let status = "active";
      //this.fastProdutoVindi.status ? (status = "active") : (status = "inative");

      if (!this.fastProdutoVindi.name) {
        this.$notify({
          group: "foo",
          type: "error",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Preencha o nome do produto",
        });
      } else if (!this.fastProdutoVindi.description) {
        this.$notify({
          group: "foo",
          type: "error",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Preencha a descrição do produto",
        });
      } else if (!this.fastProdutoVindi.pricing_schema.price) {
        this.$notify({
          group: "foo",
          type: "error",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Preencha o preço do produto",
        });
      } else {
        let model_product = {
          fast_plataforma: {
            id_plataforma: this.$route.params.id_plataforma,
          },
          product: {
            id: this.fast_curso.id_produto_vindi,
            id_curso: this.$route.params.id_curso,
            id_plataforma: this.$route.params.id_plataforma,
            status: status,
            name: this.fastProdutoVindi.name,
            description: this.fastProdutoVindi.description,
            pricing_schema: {
              price: this.fastProdutoVindi.pricing_schema.price,
            },
          },
        };
        this.$store.state.fastCarregando = true;
        if (this.fast_curso.id_produto_vindi) {
          try {
            let resp = await fetch(
              settings.endApiFastEad + "api/fast_curso/atualiza_produto_vindi",
              this.fastAjaxOptions(
                "POST",
                JSON.stringify(model_product.product)
              )
            );
            let json = await resp.json();
            this.$store.state.fastCarregando = false;
            this.getFastCurso();
            this.$notify({
              group: "foo",
              type: "success",
              duration: 5000,
              speed: 1000,
              title: "Status",
              text: "Produto atualizado com sucesso",
            });
          } catch (e) {
            this.$store.state.fastCarregando = false;
            console.log("Erro", e);
          }
        } else {
          try {
            let resp = await fetch(
              settings.endApiFastEad + "api/fast_curso/cria_produto_vindi",
              this.fastAjaxOptions("POST", JSON.stringify(model_product))
            );
            let json = await resp.json();
            this.$store.state.fastCarregando = false;
            this.getFastCurso();
            this.$notify({
              group: "foo",
              type: "success",
              duration: 5000,
              speed: 1000,
              title: "Status",
              text: "Produto atualizado com sucesso",
            });
          } catch (e) {
            this.$store.state.fastCarregando = false;
            console.log("Erro", e);
          }
        }
      }
    },
    // Certificado
    async alterarNotasCurso() {
      if (this.fast_curso.nota_media || this.fast_curso.nota_maxima) {
        if (!this.fast_curso.nota_media)
          this.fast_curso.nota_media = this.fast_curso.nota_maxima;
        if (!this.fast_curso.nota_maxima)
          this.fast_curso.nota_maxima = this.fast_curso.nota_media;
        let fast_curso_plataforma = {
          id_curso: this.fast_curso.id_curso,
          id_plataforma: this.$route.params.id_plataforma,
          nota_maxima: this.fast_curso.nota_maxima,
          nota_media: this.fast_curso.nota_media,
        };
        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/fast_curso_plataforma/atualiza_notas",
            this.fastAjaxOptions("POST", JSON.stringify(fast_curso_plataforma))
          );
          let json = await resp.json();
          console.log(json);
          this.getFastCurso();
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Notas atualizadas com sucesso",
          });
        } catch (e) {
          console.log("Erro", e);
        }
      }
    },
    verificaFormaCertificado() {
      if (this.formaObterCertificado == "C") {
        this.fast_curso.nota_maxima = 0;
        this.fast_curso.nota_media = 0;
        this.alterarNotasCurso();
      }
    },
    async getFastPlataformaCertificados() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_certificado/lista?id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastPlataformaCertificados = obj;
        } else {
          this.fastPlataformaCertificados = [];
        }
        //console.log(this.fastPlataformaCertificados);
        this.$store.state.fastCarregando = false;
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false;
      }
    },
    async getFastCursoCertificados() {
      this.$store.state.fastCarregando = true;
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso_certificado/lista?id_curso=" +
            this.$route.params.id_curso,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastCursoCertificados = obj[0];
        } else {
          this.fastCursoCertificados = [];
        }
        this.getFastPlataformaCertificados();
        console.log("getFastCursoCertificados", this.fastCursoCertificados);
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false;
      }
    },
    tabClicked(selectedTab) {
      console.log("Current tab re-clicked:" + selectedTab.tab.name);
      if (selectedTab.tab.name == "Certificado") {
        this.getFastCursoCertificados();
      }
    },
    async insereFastCursoCertificado(certificado) {
      const fast_curso_certificado = {
        id_certificado: certificado.id_certificado,
        id_curso: this.$route.params.id_curso,
        html_certificado: certificado.html_template,
      };

      this.$store.state.fastCarregando = true;

      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_curso_certificado/insere",
          this.fastAjaxOptions("POST", JSON.stringify(fast_curso_certificado))
        );
        let json = await resp.json();
        this.getFastCursoCertificados();
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Certificado inserido com sucesso",
        });
        this.$store.state.fastCarregando = false;
      } catch (e) {
        this.$store.state.fastCarregando = false;
        console.log("Erro", e);
      }
    },
    async excluiFastCursoCertificado(certificado) {
      this.$store.state.fastCarregando = true;
      const fast_curso_certificado = {
        id_certificado: certificado.id_certificado,
        id_curso: this.$route.params.id_curso,
        html_certificado: certificado.html_template,
      };
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_curso_certificado/exclui",
          this.fastAjaxOptions("POST", JSON.stringify(fast_curso_certificado))
        );
        let json = await resp.json();
        console.log(json);
        this.getFastCursoCertificados();
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Certificado excluído com sucesso",
        });
        this.$store.state.fastCarregando = false;
      } catch (e) {
        this.$store.state.fastCarregando = false;
        console.log("Erro", e);
      }
    },
    exibeTooltip(tipo) {
      switch (tipo) {
        case "origem":
          return (
            '<p class="text-info"><b>Origem</b></p>' +
            '<ol class="text-info pl-4 pr-4">' +
            '<li><b>Fornecedores</b> - Ao selecionar esta opção a aba <span class="text-danger">Fornecedores</span> irá aparecer, permitindo a associação de fornecedores cadastrados no FastEAD ao curso criado.</li>' +
            '<li><b>Produção OnDemand</b> - Determina que é um curso a ser ofertado na loja virtual. Ao selecionar esta opção a aba <span class="text-danger">Financeiro</span> irá aparecer, permitindo a configuração do preço e ativação de sua venda.</li>' +
            "<li><b>Produção do Cliente</b> - Determina que o curso é criado por um cliente.</li>" +
            "</ol>"
          );
          break;
        case "carga_horaria":
          return '<p class="text-info"><b>Carga horária</b></p> <span class="text-info">Carga horária do curso em <span class="text-danger">horas</span></span>';

          break;
        case "acesso":
          return (
            '<p class="text-info"><b>Acesso</b></p>' +
            '<ol class="text-info pl-4 pr-4">' +
            "<li><b>Público</b> - Este curso pode ser acessado por todos usuários dessa plataforma e plataformas filhas.</li>" +
            '<li><b class="text-danger">Restrito</b> - Ao selecionar essa opção a aba <span class="text-danger">Restrições</span> irá aparecer, permitindo que o acesso a esse curso seja restrito a determinadas plataformas filhas ou usuários dessa plataforma.</li>' +
            "</ol>"
          );

          break;
        case "curso_publicado":
          return (
            '<p class="text-info"><b>Publicação</b></p>' +
            '<ol class="text-info pl-4 pr-4">' +
            "<li><b>Publicado</b> - O curso estará visível para usuários de plataforma e/ou loja virtual.</li>" +
            '<li><b class="text-danger">Não Publicado</b> - O curso ficará visível apenas para administradores.</li>' +
            "</ol>"
          );

          break;
        case "liberacao_automatica":
          return (
            '<p class="text-info"><b>Liberação automática</b></p>' +
            '<ol class="text-info pl-4 pr-4">' +
            "<li><b>Liberado</b> - O curso será liberado para utilização logo após a confirmação de compra.</li>" +
            '<li><b class="text-danger">Não Liberado</b> - O curso não terá seu conteúdo disponível, irá ser exibido na loja virtual como curso sob demanda.</li>' +
            "</ol>"
          );

          break;
        case "gera_certificado":
          return (
            '<p class="text-info"><b>Certificado</b></p>' +
            '<span class="text-info">' +
            'Ao habilitar essa opção a aba <span class="text-danger">Certificado</span> irá aparecer, permitindo a configuração do certificado que será disponibilizado para o aluno após a conclusão do curso.' +
            "</span>"
          );

          break;
        case "reiniciar_curso":
          return (
            '<p class="text-info"><b>Reiniciar curso</b></p>' +
            '<span class="text-info">' +
            "Determina se o aluno poderá reiniciar o curso, reiniciando seu progresso e suas notas." +
            "</span>"
          );

          break;
        default:
          break;
      }
    },
    // Permissões
    async atualizaPermissoesCurso() {
      const fast_curso = {
        id_curso: this.$route.params.id_curso,
        filhas_adicionam_aula: this.fast_curso.filhas_adicionam_aula,
        filhas_alteram_aula: this.fast_curso.filhas_alteram_aula,
      };

      this.$store.state.fastCarregando = true;

      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_curso/atualiza_permissoes",
          this.fastAjaxOptions("POST", JSON.stringify(fast_curso))
        );
        let json = await resp.json();
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Permissão atualizada com sucesso",
        });
        this.$store.state.fastCarregando = false;
      } catch (e) {
        this.$store.state.fastCarregando = false;
        console.log("Erro", e);
      }
    },
    exibeModalClonarUnidade(){
      this.showModal('modalClonarUnidade')
    },
    async clonarCurso(){
      this.$store.state.fastCarregando = true
      const clonarUnidade = {
        id_plataforma: this.$route.params.id_plataforma,
        id_curso: this.$route.params.id_curso,
        nome_curso_novo: this.fastCursoNomeClone
      }
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_curso/clonar",
          this.fastAjaxOptions("POST", JSON.stringify(clonarUnidade))
        );
        if(resp.success === false){
          console.log(`${resp.error} ${resp.description ? resp.description : ""}`)
          this.exibeToasty(`${resp.error} ${resp.description ? resp.description : ""}`, "error")
          this.$store.state.fastCarregando = false 
        } else {
          this.hideModal('modalClonarUnidade')
          this.exibeToasty("Alterações salvas", "success")
          const data = await resp.json();
          window.location.href = '/plataforma/' + this.$route.params.id_plataforma + '/secretaria/unidade-curricular/' + data.Id_curso;
        }
      } catch (err) {
        this.exibeToasty("Ocorreu um erro", "error")
        console.error(err);
        this.$store.state.fastCarregando = false 
      }
    },
  },
};
</script>

<style scope>
/* Tooltip */
.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: #fff;
  color: #000;
  border-radius: 16px;
  padding: 5px 10px 4px;
  -webkit-box-shadow: 1px 1px 5px 0px rgba(50, 50, 50, 0.75);
  -moz-box-shadow: 1px 1px 5px 0px rgba(50, 50, 50, 0.75);
  box-shadow: 1px 1px 5px 0px rgba(50, 50, 50, 0.75);
  width: 300px;
  min-width: 300px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
/* */
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}

/* Certificado */
.fd-certificado-main {
  height: auto !important;
}
.fd-certificado-main img {
  max-width: 100% !important;
}
.fd-certificado-content {
  grid-template-columns: 100% !important;
}

.message-field-label {
    display: flex;
    align-items: left;
    font-size: 12px;
    color:#495057;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
</style>
